import { ArrayFieldProps } from "../../Form/ArrayInput";
import { FieldConfig, FieldType } from "../../Form/Form";
import { isRequired } from "../../Form/utils";

export const config: { [key: string]: FieldConfig<FieldType>[] & ArrayFieldProps } = {
  "0": [
    {
      accessor: "url",
      title: "URL",
      description: "",
      type: "text",
      validate: isRequired,
    },
  ],
  "1": [
    {
      accessor: "refreshInterval",
      title: "Refresh Interval (minutes)",
      type: "number",
    },
    {
      accessor: "rowCount",
      title: "Row count",
      type: "number",
    },
  ],
  "2": [
    {
      accessor: "display",
      title: "Display Settings",
      type: "checkbox",
      values: [
        {
          label: "Title",
          description: "Show or hide the feeds title",
          accessor: "title",
          iconActive: "checkSquare",
          iconInactive: "square",
        },
        {
          label: "Description",
          description: "Show or hide the feeds description",
          accessor: "description",
          iconActive: "checkSquare",
          iconInactive: "square",
        },
      ],
    },
  ],
  "3": [
    {
      accessor: "additionalKeys",
      title: "Additional keys",
      description: "Define any additional keys from the RSS response that you want displayed.",
      type: "array",
      value: "",
      component: "rssAdditionalKeys",
    },
  ],
};
