import { useDispatch } from "react-redux";
import { FieldType, FieldConfig } from "../../components/Form/Form";

import React from "react";
import { updateLayoutSettings } from "../../views/admin/reducer";
import { useSelector } from "../../utils";
import { getCurrentLayout, getCurrentThemeSelectOption } from "../../views/admin/selectors";
import { options } from "../../config/themes/themeSelectOptions";
import { Select } from "../Form";
import classnames from "classnames";
import { iconMap } from "../Form/icons";
export const config: { [key: string]: FieldConfig<FieldType>[] } = {
  "0": [
    {
      accessor: "bgColor",
      title: "Background Color",
      description: "",
      type: "color",
    },
  ],
  "2": [
    {
      accessor: "isPublic",
      title: "Public?",
      description: "If public this layout can be accessed by anyone online.",
      type: "radio",
      values: [
        {
          label: "Enabled",
          description: "A QR code will be generated allowing anyone to submit an update.",
          value: true,
          icon: "mic",
        },
        {
          label: "Disabled",
          description: "No QR code and no outside submissions.",
          value: false,
          icon: "micOff",
        },
      ],
    },
  ],
};

export const LayoutSettingsForm = () => {
  const dispatch = useDispatch();
  const currentLayout = useSelector(getCurrentLayout);
  const currentThemeSelectOption = useSelector(getCurrentThemeSelectOption);

  const baseIconClassnameString =
    "w-6 h-6 flex items-center justify-center p-1 rounded-md bg-info text-white";

  const vertIconClassname = classnames(baseIconClassnameString, {
    dark: currentLayout?.settings?.compact === "vertical",
  });

  const horizIconClassname = classnames(baseIconClassnameString, {
    dark: currentLayout?.settings?.compact === "horizontal",
  });

  const noneIconClassname = classnames(baseIconClassnameString, {
    dark: currentLayout?.settings?.compact === "none" || !currentLayout?.settings?.compact,
  });

  return (
    <>
      <div className="mb-5">
        <label className="block mb-2 text-sm font-medium dark:text-blue-100 text-gray-700">
          Theme
        </label>
        <p className="block text-xs dark:text-blue-100 text-gray-500 pr-3 mb-2">
          Pick from different color themes for your layout.
        </p>

        <Select
          options={options}
          onChange={(theme) => {
            dispatch(updateLayoutSettings({ theme: theme.value }));
          }}
          value={currentThemeSelectOption}
        />
      </div>

      <div className="mb-5">
        <label className="block mb-2 text-sm font-medium dark:text-blue-100 text-gray-700">
          Compact Layout
        </label>

        <div
          className="flex flex-row flex-nowrap items-start mt-3  cursor-pointer"
          onClick={() => dispatch(updateLayoutSettings({ compact: "vertical" }))}
        >
          <div className="flex mr-2 items-start justify-center">
            <div className={vertIconClassname}>
              {currentLayout?.settings?.compact === "vertical"
                ? iconMap["checkSquare"]
                : iconMap["square"]}
            </div>
          </div>
          <div className="flex flex-col items-start">
            <label className="block mb-0 text-sm font-medium dark:text-blue-100 text-gray-700">
              Vertical
            </label>

            <p className="block text-xs dark:text-blue-100 text-gray-500 pr-3">
              Automatically compact layout towards the top
            </p>
          </div>
        </div>

        <div
          className="flex flex-row flex-nowrap items-start mt-3  cursor-pointer"
          onClick={() => dispatch(updateLayoutSettings({ compact: "horizontal" }))}
        >
          <div className="flex mr-2 items-start justify-center">
            <div className={horizIconClassname}>
              {currentLayout?.settings?.compact === "horizontal"
                ? iconMap["checkSquare"]
                : iconMap["square"]}
            </div>
          </div>
          <div className="flex flex-col items-start">
            <label className="block mb-0 text-sm font-medium dark:text-blue-100 text-gray-700">
              Horizontal
            </label>

            <p className="block text-xs dark:text-blue-100 text-gray-500 pr-3">
              Automatically compact layout towards the left
            </p>
          </div>
        </div>

        <div
          className="flex flex-row flex-nowrap items-start mt-3  cursor-pointer"
          onClick={() => dispatch(updateLayoutSettings({ compact: "none" }))}
        >
          <div className="flex mr-2 items-start justify-center">
            <div className={noneIconClassname}>
              {currentLayout?.settings?.compact === "none" || !currentLayout?.settings?.compact
                ? iconMap["checkSquare"]
                : iconMap["square"]}
            </div>
          </div>
          <div className="flex flex-col items-start">
            <label className="block mb-0 text-sm font-medium dark:text-blue-100 text-gray-700">
              None
            </label>

            <p className="block text-xs dark:text-blue-100 text-gray-500 pr-3">
              No automatic adjustment. Widgets can be moved freely.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
