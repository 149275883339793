import React, { FC, ReactElement } from "react";

import classnames from "classnames";
import { MDVariant } from "../../types";
import { Loader } from "../LoadingOverlay";

interface Props {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  label?: string;
  loading?: boolean;
  icon?: ReactElement;
  variant?: MDVariant;
  additionalClassnames?: string;
  type?: "button" | "submit";
  size?: "sm" | "md" | "lg";
  outline?: boolean;
}

const Button: FC<Props> = ({
  type,
  disabled,
  onClick,
  label,
  loading,
  icon,
  variant,
  size,
  additionalClassnames,
  outline,
}) => {
  const wrapperClassname = classnames(additionalClassnames, "btn", {
    "btn-success": variant === "success",
    "btn-default": variant === "default" || !variant,
    "btn-info": variant === "info",
    "btn-secondary": variant === "secondary",
    "btn-danger": variant === "danger",
    "text-sm font-normal": size === "sm",
    "text-md": size === "md" || !size,
    "text-lg": size === "lg",
    outline: outline,
    "items-center": !label,
  });

  const iconClassname = classnames("icon", {
    "ml-2": label,
  });
  return (
    <button type={type} disabled={disabled} onClick={onClick} className={wrapperClassname}>
      {label}
      {(icon || loading) && (
        <div className={iconClassname}>
          {icon && !loading ? icon : null}
          {loading ? <Loader size="sm" /> : null}
        </div>
      )}
    </button>
  );
};

export default Button;
