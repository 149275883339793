import { ReactElement, FC, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import classnames from "classnames";
import { MDVariant } from "../../types";

interface Props {
  open: boolean;
  title: string;
  children: ReactElement | ReactElement[] | string;
  loading?: boolean;
  icon?: ReactElement;
  variant?: MDVariant;
  handleClose: () => void;
  size: "sm" | "lg" | "xl";
}

const Modal: FC<Props> = ({ open, handleClose, title, children, size, variant, icon }) => {
  const modalClassname = classnames("modal base-01", {
    sm: size === "sm",
    lg: size === "lg",
    xl: size === "xl",
  });

  const iconClassname = classnames(
    "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10",
    {
      "bg-danger": variant === "danger",
      "bg-default": variant === "default" || !variant,
      "bg-success": variant === "success",
      "bg-info": variant === "info",
    }
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed z-50 inset-0 overflow-y-auto" open={open} onClose={handleClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={modalClassname}>
              <div className="sm:flex sm:items-start">
                {icon && <div className={iconClassname}>{icon}</div>}
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-grow">
                  <Dialog.Title as="h3" className="text-xl mt-2 mb-5 leading-6 font-medium ">
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">{children}</div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default Modal;
