import { IThemes } from "./utils";

// import defaultTheme from "./default";
// import minimal from "./minimal";
// import red from "./red";

import * as themeConfigs from "./themes";

export const DEFAULT_THEME: string = "myDomDefault";

export const themes: IThemes = {
  ...themeConfigs,
};
