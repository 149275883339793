import { configureStore } from "@reduxjs/toolkit";

import { reducer as userReducer } from "../views/auth";
import { reducer as roomReducer } from "../views/admin";

import { notificationsReducer } from "./reducers";
import { notificationsMiddleware } from "./middlewares";

import { createLogger } from "redux-logger";

const logger = createLogger({
  collapsed: true,
});

export const store = configureStore({
  reducer: { user: userReducer, room: roomReducer, notifications: notificationsReducer },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    notificationsMiddleware,
    logger,
  ],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
