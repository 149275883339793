import { Oval } from "svg-loaders-react";
import classnames from "classnames";

export const Loader = ({
  size,
  additionalClassnames,
}: {
  size: "sm" | "md" | "lg";
  additionalClassnames?: string;
}) => {
  const classNames = classnames("stroke-current stroke-2", additionalClassnames, {
    "w-6 h-6": size === "sm",
    "w-12 h-124": size === "md",
    "w-16 h-16": size === "lg",
  });
  return <Oval className={classNames} />;
};

const LoadingOverlay = () => (
  <div className="z-50 fixed top-0 left-0 base-02 h-full w-full flex items-center justify-center">
    <div className="row my-5 py-5 d-flex justify-content-center align-items-center">
      <Loader size="md" />
    </div>
  </div>
);

export default LoadingOverlay;
