import React, { ReactElement } from "react";
import ReactGridLayout from "react-grid-layout";

import { Layout, Activity, Calendar, Camera, Clock, Type, Sun, Rss } from "react-feather";

import { AnyWidget, WidgetType } from "../../types";

import { config as clockConfig } from "./ClockWidget/config";
import { config as dateConfig } from "./DateWidget/config";
import { config as photoConfig } from "./PhotoWidget/config";
import { config as staticTextConfig } from "./StaticTextWidget/config";
import { config as updatesConfig } from "./UpdateWidget/config";
import { config as weatherConfig } from "./WeatherWidget/config";
import { config as rssConfig } from "./RssWidget/config";
import { config as iFrameConfig } from "./IFrameWidget/config";

export const defaultWidgetConfigs: {
  [key: string]: AnyWidget;
} = {
  iframe: {
    id: "iframe",
    label: "iFrame",
    type: "iframe",
    fields: iFrameConfig,
    url: "",
    refreshInterval: 60,
  },
  rss: {
    id: "rss",
    label: "RSS",
    type: "rss",
    fields: rssConfig,
    url: "https://reddit.com/r/worldnews/.rss",
    refreshInterval: 60,
    rowCount: 10,
    display: {
      title: true,
      description: true,
    },
  },
  weather: {
    id: "weather",
    label: "Weather",
    description: "United States only for now.",
    type: "weather",
    fields: weatherConfig,
    location: "Hamburg, Germany",
    units: "metric",
    showAstrology: "show",
  },
  clock: {
    id: "clock",
    label: "Clock",
    type: "clock",
    fields: clockConfig,
    clockLabel: "",
    timezone: "America/New_York",
  },
  date: {
    id: "date",
    label: "Calendar / Events",
    type: "date",
    fields: dateConfig,
    events: [],
    show: {
      events: true,
      title: true,
    },
  },
  photos: {
    id: "photos",
    label: "Photos",
    type: "photos",
    fields: photoConfig,
    url: "https://picsum.photos/id/237/1600/800",
    fill: "fill",
    position: "center",
  },
  "static-text": {
    id: "static-text",
    label: "Text",
    type: "static-text",
    fields: staticTextConfig,
    text: "What I cannot create, I do not understand. - Feynman",
    size: 24,
    color: "#1a6aff",
    font: "Helvetica, sans-serif",
    css: "",
  },
  updates: {
    id: "updates",
    label: "Updates",
    type: "updates",
    fields: updatesConfig,
    outsideEnabled: "enabled",
    showTodayOnly: "today",
    freshnessThreshold: 30,

    updateUrl: "",
    updates: [],
  },
};

export const rglConfig: {
  [key in WidgetType]: Partial<ReactGridLayout.Layout>;
} = {
  iframe: {
    w: 12,
    h: 12,
  },
  rss: {
    w: 6,
    h: 12,
  },
  weather: {
    w: 12,
    h: 6,
  },
  photos: {
    w: 12,
    h: 6,
  },
  clock: {
    w: 4,
    h: 4,
  },
  date: {
    w: 12,
    h: 7,
  },
  "static-text": {
    w: 12,
    h: 2,
  },
  updates: {
    w: 12,
    h: 6,
  },
};

export const defaultWidgets: { label: string; icon: ReactElement; type: WidgetType }[] = [
  { label: "Clock", icon: <Clock />, type: "clock" },
  { label: "Calendar / Events", icon: <Calendar />, type: "date" },
  { label: "Photo", icon: <Camera />, type: "photos" },
  { label: "Text", icon: <Type />, type: "static-text" },
  { label: "Updates", icon: <Activity />, type: "updates" },
  { label: "Weather", icon: <Sun />, type: "weather" },
  { label: "RSS", icon: <Rss />, type: "rss" },
  { label: "iFrame Embed", icon: <Layout />, type: "iframe" },
];
