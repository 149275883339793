import React, { FC, useMemo } from "react";

import { Repeat } from "react-feather";
import Calendar from "react-calendar";
import { addDays, getDate, getDay, getMonth, isSameDay } from "date-fns";

import { DateWidget as DateWidgetType, RecurringEvent } from "../../../types";
import { WidgetWrapper } from "../WidgetWrapper";

import "./styles.css";
const RepeatBadge: FC<{ label: string }> = ({ label }) => {
  return (
    <div className="flex uppercase flex-row items-center justify-center text-background bg-secondary dark:bg-secondary dark:text-backgroundDark p-1 rounded-md text-xs">
      <Repeat size={14} className="mr-1" />
      {label}
    </div>
  );
};

const today = new Date();

const returnValidEvents = (events: RecurringEvent[], forDate: Date) => {
  return events
    .filter((event) => {
      const repeatsOn = event.repeats;
      const eventDate = new Date(`${event.on}T00:00:00`);
      const isSame = isSameDay(forDate, eventDate);

      if (repeatsOn === "never" && !isSame) {
        return false;
      }

      if (repeatsOn === "weekly") {
        const todayDay = getDay(forDate);
        const eventDay = getDay(eventDate);
        return todayDay === eventDay;
      }

      if (repeatsOn === "monthly") {
        const todayDayOfMonth = getDate(forDate);
        const eventDayOfMonth = getDate(eventDate);

        return todayDayOfMonth === eventDayOfMonth;
      }

      if (repeatsOn === "yearly") {
        const todayDayOfMonth = getDate(forDate);
        const todayMonth = getMonth(forDate);

        const eventDayOfMonth = getDate(eventDate);
        const eventMonth = getMonth(eventDate);

        return todayDayOfMonth === eventDayOfMonth && todayMonth === eventMonth;
      }

      return true;
    })
    .map((event, index) => {
      return (
        <div key={index} className="flex flex-row items-start justify-between mb-2">
          <div className="bg-highlight dark:bg-highlightDark dark flex flex-initial w-2 h-2 rounded-full mt-2 mr-3" />
          <div className="flex flex-col flex-1 items-start">
            <p className="text-md font-medium text-secondary dark:text-secondaryDark">
              {event.label}
            </p>
            {event.description && (
              <p className="text-xs text-primary dark:text-primaryDark">{event.description}</p>
            )}
          </div>

          {event.repeats && <RepeatBadge label={event.repeats} />}
        </div>
      );
    });
};

const DateWidget: FC<DateWidgetType> = ({ ...widget }) => {
  const { events, show, title, description } = widget;

  const renderedTodayEvents = useMemo(() => {
    return returnValidEvents(events, today);
  }, [events]);

  const renderedTomorrowEvents = useMemo(() => {
    const tomorrow = addDays(today, 1);
    return returnValidEvents(events, tomorrow);
  }, [events]);

  return (
    <WidgetWrapper initialValues={{ events, show }} canEdit={true} widget={widget}>
      <div className="flex flex-col items-start flex-grow-0 h-full">
        {show.title && (
          <div className="mb-3">
            <p className="text-lg font-medium text-primary dark:text-primaryDark">{title}</p>
            <p className="text-md text-secondary dark:text-secondaryDark">{description}</p>
          </div>
        )}
        <div className="w-full h-full flex flex-row items-start justify-center">
          <Calendar
            className="light flex flex-col flex-1"
            prev2Label={null}
            next2Label={null}
            prevLabel={<></>}
            nextLabel={<></>}
          />
          {show.events && (
            <div className="px-3 flex flex-1 flex-col justify-start h-full">
              <p className="text-primary dark:text-primaryDark font-mono border-b-2 border-primary dark:border-primaryDark mb-2 w-full text-sm uppercase">
                Today
              </p>
              <div className="max-h-full overflow-auto mb-3">
                {renderedTodayEvents.length > 0 ? (
                  renderedTodayEvents
                ) : (
                  <p className="py-10 text-center m-0 text-sm text-secondary dark:text-secondaryDark">
                    All clear
                  </p>
                )}
              </div>

              <p className="text-primary dark:text-primaryDark font-mono border-b-2 border-primary dark:border-primaryDark mb-2 w-full text-sm uppercase">
                Tomorrow
              </p>
              <div className="max-h-full overflow-auto">
                {renderedTomorrowEvents.length > 0 ? (
                  renderedTomorrowEvents
                ) : (
                  <p className="py-10 text-center m-0 text-sm text-secondary dark:text-secondaryDark">
                    All clear
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default DateWidget;
