import { FieldConfig, FieldType } from "../../Form/Form";
import { isRequired } from "../../Form/utils";

export const config: { [key: string]: FieldConfig<FieldType>[] } = {
  "0": [
    {
      accessor: "url",
      title: "iFrame URL",
      type: "text",
      value: "",
      description:
        "The url for the iFrame you want to embed. Note: don't paste the full iframe code! Just the url/src of it.",
      validate: isRequired,
    },
  ],
  "1": [
    {
      accessor: "refreshInterval",
      title: "Refresh Interval in minutes",
      type: "number",
      value: "60",
    },
  ],
};
