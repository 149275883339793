import { createAsyncThunk } from "@reduxjs/toolkit";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import { Room } from "../../types";
import { defaultNotifications, add } from "../../store/reducers";

export const dbAddRoom = createAsyncThunk(
  "room/api/add",
  async ({ uid, room }: { uid: string; room: Room }, { dispatch }) => {
    const ref = await firebase.firestore().collection("users").doc(uid).collection("rooms").doc();

    await ref.set({ ...room, id: ref.id });

    dispatch(add(defaultNotifications("Room Added", `${room.name} was added.`)["success"]));
    return { ...room, id: ref.id };
  }
);

export const dbUpdateRoom = createAsyncThunk(
  "room/api/update",
  async ({ uid, room }: { uid: string; room: Room }) => {
    const ref = await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("rooms")
      .doc(room.id);
    await ref.set({ ...room });

    return room;
  }
);

export const dbDeleteRoom = createAsyncThunk(
  "room/api/delete",
  async ({ uid, room }: { uid: string; room: Room }, { dispatch }) => {
    const ref = await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("rooms")
      .doc(room.id);

    await ref.delete();

    // dispatch(dbGetAllRooms({ uid }));
    dispatch(add(defaultNotifications("Room Deleted", `${room.name} was deleted.`)["info"]));
    return room;
  }
);

export const dbGetAllRooms = createAsyncThunk(
  "room/api/getAll",
  async ({ uid }: { uid: string }) => {
    const ref = await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("rooms")
      .orderBy("createdOn", "asc");

    const docs = await (await ref.get()).docs;
    return docs.map((doc) => doc.data() as Room);
  }
);

export const dbGetRoomById = createAsyncThunk(
  "room/api/getById",
  async ({ uid, roomId }: { uid: string; roomId: string }) => {
    const ref = await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("rooms")
      .doc(roomId);
    return (await (await ref.get()).data()) as Room;
  }
);
