import React, { FC } from "react";

import { CheckboxInputGroup } from "./CheckboxInput";
import { ColorInput } from "./ColorInput";

import { FieldConfig, FieldType } from "./Form";
import { RadioInputGroup } from "./RadioInput";
import { SelectInput } from "./SelectInput";
import { TextInput } from "./TextInput";
import { EmojiInput } from "./EmojiInput";

import { ArrayFieldProps, ArrayInput } from "./ArrayInput";

const fieldMap: { [key in FieldType]: FC<FieldConfig<any>> } = {
  text: TextInput,
  time: TextInput,
  number: TextInput,
  date: TextInput,
  radio: RadioInputGroup,
  checkbox: CheckboxInputGroup,
  textarea: TextInput,
  select: SelectInput,
  color: ColorInput,
  array: ArrayInput,
  emoji: EmojiInput,
};

export const InputWrapper: FC<FieldConfig<FieldType> & ArrayFieldProps> = ({ ...config }) => {
  const FieldComponent = fieldMap[config.type];

  return (
    <div className="flex flex-row w-full">
      {config.icon && (
        <div className="flex mr-3 items-start justify-center">
          <div className="w-8 h-8 flex items-center justify-center p-2 rounded-md bg-default text-white">
            {config.icon}
          </div>
        </div>
      )}
      <div className="flex flex-col flex-1 items-start">
        <label className="block mb-0  font-medium dark:text-blue-100 text-gray-700">
          {config.title}
        </label>
        {config.description && (
          <p className="block text-sm font-medium dark:text-blue-100 text-gray-500">
            {config.description}
          </p>
        )}
        <div className="mt-3 flex flex-1 w-full">
          <FieldComponent {...config} />
        </div>
      </div>
    </div>
  );
};
