import { FieldConfig, FieldType } from "../../Form/Form";

export const config: { [key: string]: FieldConfig<FieldType>[] } = {
  "0": [
    {
      accessor: "outsideEnabled",
      title: "Outside Submissions",
      type: "radio",
      condition: [
        {
          when: "outsideEnabled",
          is: true,
          children: { accessor: "updateUrl", type: "text", title: "Public URL", disabled: true },
        },
      ],
      values: [
        {
          label: "Enabled",
          description: "A QR code will be generated allowing anyone to submit an update.",
          value: "enabled",
          icon: "mic",
        },
        {
          label: "Disabled",
          description: "No QR code and no outside submissions.",
          value: "disabled",
          icon: "micOff",
        },
      ],
    },
    {
      accessor: "showTodayOnly",
      title: "Today Only",
      type: "radio",
      values: [
        {
          label: "Today Only",
          description: "Show only updates from today.",
          value: "today",
          icon: "play",
        },
        {
          label: "All",
          description: "Show all updates that exists for this widget.",
          value: "all",
          icon: "rewind",
        },
      ],
    },
  ],
  "1": [
    {
      accessor: "freshnessThreshold",
      title: "Freshness",
      description:
        "The time - in minutes - for which an update will be considered fresh and displayed separate from other updates.",
      type: "number",
    },
  ],
  // "2": [
  //   {
  //     accessor: "showCount",
  //     title: "Show Count",
  //     description: "How many updates should be displayed at any given time.",
  //     type: "number",
  //   },
  // ],
};
