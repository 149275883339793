export const options = [
  { label: "Default", value: "myDomDefault" },
  { label: "Turquoise", value: "TURQUOISE" },
  { label: "Emerald", value: "EMERALD" },
  { label: "Peter River", value: "PETERRIVER" },
  { label: "Amethyst", value: "AMETHYST" },
  { label: "Wet Asphalt", value: "WETASPHALT" },
  { label: "Green Sea", value: "GREENSEA" },
  { label: "Nephret", value: "NEPHRITIS" },
  { label: "BELIZE HOLE", value: "BELIZEHOLE" },
  { label: "WISTERIA", value: "WISTERIA" },
  { label: "MIDNIGHT BLUE", value: "MIDNIGHTBLUE" },
  { label: "SUN FLOWER", value: "SUNFLOWER" },
  { label: "CARROT", value: "CARROT" },
  { label: "ALIZARIN", value: "ALIZARIN" },
  { label: "CLOUDS", value: "CLOUDS" },
  { label: "CONCRETE", value: "CONCRETE" },
  { label: "ORANGE", value: "ORANGE" },
  { label: "PUMPKIN", value: "PUMPKIN" },
  { label: "POMEGRANATE", value: "POMEGRANATE" },
  { label: "SILVER", value: "SILVER" },
  { label: "ASBESTOS", value: "ASBESTOS" },
];
