import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { Layout, Room, AnyWidget } from "../../types";
import RGL from "react-grid-layout";

import { dbGetRoomById, dbGetAllRooms, dbAddRoom, dbUpdateRoom, dbDeleteRoom } from "./api";
import { LS_AUTOSAVE_KEY, readFromLocalStorage, saveToLocalStorage } from "../../utils";

export interface RoomState {
  isEditModalOpen: { [key: string]: boolean };
  isFullScreen: boolean;
  loading: boolean;
  currentIndex: number;
  all: Room[];
  cache: Room[];
  autoSave: boolean;
}

const initialState: RoomState = {
  loading: false,
  autoSave: readFromLocalStorage(LS_AUTOSAVE_KEY) || false,
  currentIndex: 0,
  all: [],
  cache: [],
  isEditModalOpen: {},
  isFullScreen: false,
};

const anyPending = isAnyOf(
  dbGetAllRooms.pending,
  dbAddRoom.pending,
  dbUpdateRoom.pending,
  dbDeleteRoom.pending,
  dbGetRoomById.pending
);
const anyRejected = isAnyOf(
  dbGetAllRooms.rejected,
  dbAddRoom.rejected,
  dbUpdateRoom.rejected,
  dbDeleteRoom.rejected,
  dbGetRoomById.rejected
);
const anyFulfilled = isAnyOf(
  dbGetAllRooms.fulfilled,
  dbAddRoom.fulfilled,
  dbUpdateRoom.fulfilled,
  dbDeleteRoom.fulfilled,
  dbGetRoomById.fulfilled
);

const roomSlice = createSlice({
  name: "rooms",
  initialState,
  reducers: {
    toggleAutoSave: (state) => {
      const nextVal = !state.autoSave;
      state.autoSave = nextVal;
      saveToLocalStorage(LS_AUTOSAVE_KEY, nextVal);
    },
    setIsFullScreen: (state, action: PayloadAction<boolean>) => {
      state.isFullScreen = action.payload;
    },

    setIsEditModalOpen: (state, action: PayloadAction<{ id: string; open: boolean }>) => {
      state.isEditModalOpen[action.payload.id] = action.payload.open;
    },

    setCurrent: (state, action: PayloadAction<number>) => {
      state.currentIndex = action.payload;
    },

    setAll: (state, action: PayloadAction<Room[]>) => {
      state.all = action.payload;
      state.cache = action.payload;
    },

    addWidgetToRoom: (
      state,
      action: PayloadAction<{ widget: AnyWidget; rglLayout: RGL.Layout }>
    ) => {
      state.all[state.currentIndex].layout.map[action.payload.widget.id] = action.payload.widget;
      state.all[state.currentIndex].layout.rglLayout.unshift(action.payload.rglLayout);
    },

    removeWidgetFromRoom: (state, action: PayloadAction<{ widgetId: string }>) => {
      state.all[state.currentIndex].layout.rglLayout = state.all[
        state.currentIndex
      ].layout.rglLayout.filter((f) => f.i !== action.payload.widgetId);

      delete state.all[state.currentIndex].layout.map[action.payload.widgetId];
    },

    /** Sets room in place */
    setRoom: (state, action: PayloadAction<Room>) => {
      state.all[state.currentIndex] = action.payload;
      state.cache[state.currentIndex] = action.payload;
    },
    /**
     *
     * @param state Admin State
     * @param action widget: AnyWidget and rglLayout: RGL.Layout - this updates in place
     */
    updateWidgetInRoom: (
      state,
      action: PayloadAction<{ widget: AnyWidget; rglLayout: RGL.Layout }>
    ) => {
      state.all[state.currentIndex].layout.map[action.payload.widget.id] = action.payload.widget;

      const found = state.all[state.currentIndex].layout.rglLayout.findIndex(
        (f) => f.i === action.payload.rglLayout.i
      );

      if (found > -1) {
        state.all[state.currentIndex].layout.rglLayout[found] = action.payload.rglLayout;
      }
    },

    /**
     *
     * @param state Admin State
     * @param action A new RGL layout array - This replaces the existing array
     */
    setRGLLayout: (state, action: PayloadAction<RGL.Layout[]>) => {
      state.all[state.currentIndex].layout.rglLayout = action.payload;
    },

    /**
     *
     * @param state Admin State
     * @param action AnyWidget - this updates the widget config in place
     */
    setWidgetConfig: (state, action: PayloadAction<AnyWidget>) => {
      state.all[state.currentIndex].layout.map[action.payload.id] = action.payload;
    },

    updateLayoutSettings: (state, action: PayloadAction<Layout["settings"]>) => {
      state.all[state.currentIndex].layout.settings = action.payload;
    },

    resetLayout: (state) => {
      state.all = state.cache;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetToInitialState: (state) => {
      state.all = [];
      state.cache = [];
      state.currentIndex = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(dbGetAllRooms.fulfilled, (state, action: PayloadAction<Room[]>) => {
      state.all = action.payload;
      state.cache = action.payload;
    });
    builder.addCase(dbGetRoomById.fulfilled, (state, action: PayloadAction<Room>) => {
      state.all[state.currentIndex] = action.payload;
      state.cache[state.currentIndex] = action.payload;
    });
    builder.addCase(dbUpdateRoom.fulfilled, (state, action: PayloadAction<Room>) => {
      state.all[state.currentIndex] = action.payload;
      state.cache[state.currentIndex] = action.payload;
    });
    builder.addCase(dbAddRoom.fulfilled, (state, action: PayloadAction<Room>) => {
      state.all.push(action.payload);
      state.cache.push(action.payload);
    });
    builder.addCase(dbDeleteRoom.fulfilled, (state, action: PayloadAction<Room>) => {
      const currentState = [...state.all];
      const newState = currentState.filter((f) => f.id !== action.payload.id);

      state.all = newState;
      state.cache = newState;

      state.currentIndex = 0;
    });
    builder.addMatcher(anyPending, (state) => {
      state.loading = true;
    });
    builder.addMatcher(anyFulfilled, (state) => {
      state.loading = false;
    });
    builder.addMatcher(anyRejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  setCurrent,
  setAll,
  updateLayoutSettings,
  resetLayout,
  setIsEditModalOpen,
  setIsFullScreen,
  addWidgetToRoom,
  updateWidgetInRoom,
  removeWidgetFromRoom,
  setRGLLayout,
  setWidgetConfig,
  toggleAutoSave,
  setRoom,
  setLoading,
  resetToInitialState,
} = roomSlice.actions;
export default roomSlice.reducer;
