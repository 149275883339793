export const myDomDefault = {
  primary: "#2563EB",
  primaryDark: "#2563EB",
  secondary: "#60A5FA",
  secondaryDark: "#60A5FA",
  tertiary: "##60A5FA",
  tertiaryDark: "##60A5FA",
  quaternary: "#63a1ef",
  quaternaryDark: "#96c4fd",
  highlight: "#EF4444",
  highlightDark: "#EF4444",
  background: "#ffffff",
  backgroundDark: "#111827",
};
