import React, { FC, useEffect, useMemo, useRef } from "react";

import RGL, { WidthProvider } from "react-grid-layout";

import { EmptyState, ExpandableNavSection } from "..";
import {
  PhotoWidget,
  defaultWidgetConfigs,
  StaticTextWidget,
  ClockWidget,
  DateWidget,
  UpdateWidget,
  WeatherWidget,
  RssWidget,
  IFrameWidget,
} from "../Widgets";
import { haveAnyWidgetsChanged, sanitizeLayout, sanitizeWidget } from "./utils";

import { WidgetList } from "./WidgetList";
import { LayoutSettingsForm } from "./LayoutSettingsForm";
import { LayoutSaveForm } from "./LayoutSaveForm";
import { useSelector } from "../../utils";
import {
  getCurrentLayoutMap,
  getCurrentRoom,
  getCurrentRGLLayout,
  getIsLayoutDirty,
  getCurrentLayout,
  getIsEditModalOpen,
  getIsFullScreen,
  getCurrentThemeName,
  data,
  getIsLoading,
} from "../../views/admin/selectors";
import { useDispatch } from "react-redux";
import {
  WidgetType,
  Layout,
  ClockWidget as ClockWidgetType,
  DateWidget as DateWidgetType,
  PhotoWidget as PhotoWidgetType,
  StaticTextWidget as StaticTextWidgetType,
  UpdateWidget as UpdateWidgetType,
  WeatherWidget as WeatherWidgetType,
  RssWidget as RssWidgetType,
  IFrameWidget as IFrameWidgetType,
} from "../../types";
import {
  addWidgetToRoom,
  setIsEditModalOpen,
  setIsFullScreen,
  setRGLLayout,
} from "../../views/admin/reducer";
import { getUser } from "../../views/auth/selectors";
import { rglConfig } from "../Widgets/config";

import { appConfig } from "../../config/appConfig";

import { applyTheme } from "../../config/themes/utils";
import { Clock, AlertCircle, Maximize2 } from "react-feather";
import { useDebouncedCallback } from "use-debounce/lib";
import { Loader } from "../LoadingOverlay";
import classnames from "classnames";

const ReactGridLayout = WidthProvider(RGL);

export const GRID_ROW_SIZE = 40;

interface Props {
  onSubmit: (layout: Layout) => void;
  layout?: Layout;
}

const LayoutEditor: FC<Props> = ({ onSubmit }) => {
  const gridRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const isEditModalOpen = useSelector(getIsEditModalOpen);
  const isFullScreen = useSelector(getIsFullScreen);

  const currentHouse = useSelector(data);
  const currentRoom = useSelector(getCurrentRoom);
  const currentLayout = useSelector(getCurrentLayout);

  const map = useSelector(getCurrentLayoutMap);
  const rglLayout = useSelector(getCurrentRGLLayout);

  const currentTheme = useSelector(getCurrentThemeName);
  const dirty = useSelector(getIsLayoutDirty);
  const user = useSelector(getUser);
  const loading = useSelector(getIsLoading);

  const handleSubmit = useDebouncedCallback(() => {
    console.log("🔁 Updating Layout");
    const sanitized = sanitizeLayout(currentLayout.rglLayout);
    const sanitizedWidgets = Object.entries(currentLayout.map).reduce((acc, [key, val]) => {
      return { ...acc, [key]: sanitizeWidget(val) };
    }, {});

    onSubmit({ ...currentLayout, rglLayout: sanitized, map: sanitizedWidgets });
  }, 3000);

  useEffect(() => {
    if (currentTheme) {
      applyTheme(currentTheme);
    } else {
      applyTheme("myDomDefault");
    }
  }, [currentTheme]);

  useEffect(() => {
    if (dirty && currentHouse.autoSave) {
      handleSubmit();
    }
  }, [dirty, currentHouse.autoSave, handleSubmit]);

  useEffect(() => {
    const handleFullScreenChange = () => {
      dispatch(setIsFullScreen(!!document.fullscreenElement));
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [dispatch]);

  const handleAddWidget = (widgetType: WidgetType) => {
    const id = `${new Date().getTime()}-${widgetType}`;
    const lastRow =
      rglLayout.reduce((acc, curr) => (curr.y + curr.h > acc ? curr.y + curr.h : acc), 0) - 1;

    const rglWidget: RGL.Layout = {
      x: 0,
      y: lastRow,
      w: rglConfig[widgetType].w || 6,
      h: rglConfig[widgetType].h || 6,
      minW: rglConfig[widgetType].minW || 1,
      minH: rglConfig[widgetType].minH || 1,
      i: id,
    };

    const widgetConfig = { ...defaultWidgetConfigs[widgetType], id };

    dispatch(addWidgetToRoom({ widget: widgetConfig, rglLayout: rglWidget }));
    dispatch(setIsEditModalOpen({ id: id, open: true }));
    handleSubmit();
  };

  const handleRglUpdate = (layout: RGL.Layout[]) => {
    if (haveAnyWidgetsChanged(rglLayout, layout)) {
      dispatch(setRGLLayout(layout));
    }
  };

  const handleSetFullScreen = async () => {
    if (!gridRef.current) {
      return;
    }
    try {
      await gridRef.current.requestFullscreen();
    } catch (e) {
      console.log(e);
    }
  };

  const renderLayout = useMemo(() => {
    const widgets = rglLayout.map((widget) => {
      if (!user?.uid || !currentRoom?.id || !widget.i) {
        return null;
      }

      const updateUrl = `${appConfig.baseUrl}/u/${user?.uid}/r/${currentRoom.id}/up/${widget.i}`;
      const config = map[widget.i];
      const wrapperClassnames = classnames({
        "border-2 border-dashed rounded-md border-blue-400 dark:border-blue-800": !isFullScreen,
      });
      if (config.type === "clock") {
        return (
          <div key={widget.i} className={wrapperClassnames}>
            <ClockWidget {...(config as ClockWidgetType)} />
          </div>
        );
      }

      if (config.type === "date") {
        return (
          <div key={widget.i} className={wrapperClassnames}>
            <DateWidget {...(config as DateWidgetType)} />
          </div>
        );
      }

      if (config.type === "photos") {
        return (
          <div key={widget.i} className={wrapperClassnames}>
            <PhotoWidget {...(config as PhotoWidgetType)} />
          </div>
        );
      }

      if (config.type === "static-text") {
        return (
          <div key={widget.i} className={wrapperClassnames}>
            <StaticTextWidget {...(config as StaticTextWidgetType)} />
          </div>
        );
      }

      if (config.type === "updates") {
        return (
          <div key={widget.i} className={wrapperClassnames}>
            <UpdateWidget {...(config as UpdateWidgetType)} updateUrl={updateUrl} />
          </div>
        );
      }

      if (config.type === "weather" && user?.uid && currentRoom?.id) {
        return (
          <div key={widget.i} className={wrapperClassnames}>
            <WeatherWidget
              {...(config as WeatherWidgetType)}
              uid={user.uid}
              roomId={currentRoom.id}
            />
          </div>
        );
      }

      if (config.type === "rss") {
        return (
          <div key={widget.i} className={wrapperClassnames}>
            <RssWidget {...(config as RssWidgetType)} uid={user.uid} roomId={currentRoom.id} />
          </div>
        );
      }

      if (config.type === "iframe") {
        return (
          <div key={widget.i} className={wrapperClassnames}>
            <IFrameWidget {...(config as IFrameWidgetType)} />
          </div>
        );
      }

      return null;
    });

    return widgets || [];
  }, [currentRoom.id, map, rglLayout, user?.uid, isFullScreen]);
  const compactType =
    currentLayout.settings.compact === "vertical"
      ? "vertical"
      : currentLayout.settings.compact === "horizontal"
      ? "horizontal"
      : null;

  return (
    <>
      <div className="p-0 flex flex-none flex-col overflow-auto w-64 md:w-72 lg:w-80 h-full justify-between border-r dark:border-blue-800">
        <div>
          <span
            className="p-2 border-b border-blue-100 dark:border-blue-700  transition-colors hover:bg-blue-100 dark:hover:bg-blue-700 flex flex-row items-center justify-between cursor-pointer base-01 "
            onClick={handleSetFullScreen}
          >
            Enter Fullscreen Mode
            <Maximize2 size={16} />
          </span>
          <ExpandableNavSection title="Widgets">
            <WidgetList onAddWidget={handleAddWidget} />
          </ExpandableNavSection>

          <ExpandableNavSection title="Layout Settings">
            <LayoutSettingsForm />
          </ExpandableNavSection>

          <ExpandableNavSection
            title="Save / Delete"
            icon={
              loading ? (
                <Loader size="sm" />
              ) : dirty && !currentHouse?.autoSave ? (
                <AlertCircle className="bg-danger rounded-full" />
              ) : dirty && currentHouse?.autoSave ? (
                <Clock className="bg-warning rounded-full" />
              ) : undefined
            }
          >
            <LayoutSaveForm handleSubmit={handleSubmit} />
          </ExpandableNavSection>
        </div>
      </div>
      <div className=" h-full flex w-full flex-1 overflow-auto  dark:bg-blue-900 bg-white dark:border-blue-800 ">
        {rglLayout.length === 0 && (
          <EmptyState
            title="This Room has no Widgets"
            description="Pick a widget from the left to get started"
          />
        )}
        {rglLayout.length > 0 && (
          <div
            ref={gridRef}
            className="overflow-y-auto min-h-full bg-background dark:bg-backgroundDark h-full w-full"
          >
            <ReactGridLayout
              cols={34}
              draggableHandle=".widget-header"
              draggableCancel=".widget-content"
              rowHeight={GRID_ROW_SIZE}
              layout={rglLayout}
              onLayoutChange={handleRglUpdate}
              isBounded={true}
              isDraggable={!isFullScreen || !isEditModalOpen}
              isResizable={!isFullScreen}
              compactType={compactType}
              preventCollision={!compactType ? true : false}
            >
              {renderLayout}
            </ReactGridLayout>
          </div>
        )}
      </div>
    </>
  );
};

export default LayoutEditor;
