import React, { FC, useEffect } from "react";
import { IFrameWidget as IFrameWidgetType } from "../../../types";
import { WidgetWrapper } from "../WidgetWrapper";
import "./style.css";

const IFrameWidget: FC<IFrameWidgetType> = ({ ...widget }) => {
  const { url, refreshInterval } = widget;
  useEffect(() => {
    // if (timezone) {
    //   const date = new Date();
    //   const timezoned = utcToZonedTime(date, timezone || "America/New_York");
    //   setValue(timezoned);
    //   timer.current = window.setInterval(() => {
    //     setValue((prev) => addSeconds(prev || timezoned, 1));
    //   }, 1000);
    // }
    // return () => {
    //   if (timer.current) {
    //     clearInterval(timer.current);
    //   }
    // };
  }, [url, refreshInterval]);

  return (
    <WidgetWrapper initialValues={{}} canEdit={true} widget={widget}>
      <iframe title={url} className="w-full h-full" src={url}></iframe>

      {/* <div className="h-full w-full flex flex-col items-center justify-center relative">
        {value && <Clock renderSecondHand={true} value={value} />}
        <span className="truncate text-primary font-bold font-mono text-sm uppercase w-full text-center mt-3">
          {clockLabel ? clockLabel : timezone}
        </span>
      </div> */}
    </WidgetWrapper>
  );
};

export default IFrameWidget;
