import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { updateWeather } from "./api";

import { WeatherWidget as WeatherWidgetType } from "../../../types";
import { WidgetWrapper } from "../WidgetWrapper";
import { CloudRain, Droplet, Eye, Sun, Thermometer, Umbrella, Wind } from "react-feather";
import { Loader } from "../../LoadingOverlay";
import { differenceInMilliseconds } from "date-fns";

const REFRESH_INTERVAL = 10_800_000;

const WeatherWidget: FC<WeatherWidgetType> = ({ ...widget }) => {
  const [loading, setLoading] = useState(false);

  const { location, weather, uid, roomId, units, showAstrology } = widget;

  const refreshForecast = useCallback(async () => {
    setLoading(true);
    try {
      await updateWeather({ location, uid, roomId, widgetId: widget.id });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [location, roomId, uid, widget.id]);

  useEffect(() => {
    const lastUpdateTime = new Date(weather?.updatedAt || "");
    const timeSinceLastUpdate = differenceInMilliseconds(new Date(), lastUpdateTime);

    if (!weather || timeSinceLastUpdate > REFRESH_INTERVAL || weather?.query !== location) {
      refreshForecast();
    }
  }, [weather, refreshForecast, location]);

  useEffect(() => {
    let timeout: number;

    timeout = window.setTimeout(() => {
      refreshForecast();
    }, REFRESH_INTERVAL);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [refreshForecast]);

  const astroSection = useMemo(() => {
    return (
      <div className="flex flex-row w-full flex-wrap mt-5 items-start">
        <span className="mr-3 flex flex-1 flex-row items-center justify-center text-background bg-secondary dark:bg-secondary dark:text-backgroundDark p-1 px-2 mb-1 rounded-md text-xs">
          Sunrise {weather?.astro?.sunrise}
        </span>
        <span className="mr-3 flex flex-1 flex-row items-center justify-center text-background bg-secondary dark:bg-secondary dark:text-backgroundDark p-1 px-2 mb-1 rounded-md text-xs">
          Sunset {weather?.astro?.sunset}
        </span>

        <span className="mr-3 flex flex-1 flex-row items-center justify-center text-background bg-secondary dark:bg-secondary dark:text-backgroundDark p-1 px-2 mb-1 rounded-md text-xs">
          Moonrise {weather?.astro?.moonrise}
        </span>
        <span className="text-background flex flex-1 flex-row items-center justify-center bg-secondary dark:bg-secondary dark:text-backgroundDark p-1 px-2 mb-1 rounded-md text-xs">
          Moonset {weather?.astro?.moonset}
        </span>
      </div>
    );
  }, [weather?.astro]);

  const weatherSection = useMemo(() => {
    const values = units === "metric" ? weather?.forecast?.metric : weather?.forecast?.imperial;
    const temperatureUnit = units === "metric" ? "C°" : "F°";
    const distanceUnit = units === "metric" ? "km" : "miles";
    const precipUnit = units === "metric" ? "mm" : "inches";
    const speedUnit = units === "metric" ? "mph" : "km/h";
    const updatedDate = weather?.updatedAt ? new Date(weather?.updatedAt) : new Date();

    const updatedDay = updatedDate.toLocaleDateString();
    const updatedTime = updatedDate.toLocaleTimeString();

    return (
      <div className="flex flex-col text-secondary items-start justify-start w-full">
        <div className="flex flex-row justify-between w-full mb-3">
          <div className="flex flex-col flex-1">
            {weather?.updatedAt && (
              <p className="text-xs text-primary truncate">
                Updated on {updatedDay} at {updatedTime}
              </p>
            )}
            <h1 className="font-bold text-primary text-3xl mb-0">{weather?.region}</h1>
            <h2 className="text-lg text-primary mb-0 -mt-1">{weather?.country}</h2>
          </div>

          <div className="flex flex-col flex-1 text-xl font-bold px-3">
            <div className="flex flex-row items-center justify-end mb-3">
              {weather?.forecast?.chanceOfRain}%
              <Umbrella className="w-8 h-8 bg-primary border-primary p-2 rounded-full text-background dark:text-backgroundDark ml-3" />
            </div>
            <div className="flex flex-row items-center justify-end truncate">
              {values?.temperatureMin} - {values?.temperatureMax}
              {temperatureUnit}
              <Thermometer className="w-8 h-8 bg-primary border-primary p-2 rounded-full text-background dark:text-backgroundDark ml-3" />
            </div>
          </div>
        </div>

        <div className="text-secondary flex flex-row justify-end w-full truncate">
          <CloudRain className="w-6 h-6 bg-primary border-primary p-1 rounded-full text-background dark:text-backgroundDark mr-3" />
          {values?.precipitation}
          {precipUnit}
          <Wind className="w-6 h-6 bg-primary border-primary p-1 rounded-full text-background dark:text-backgroundDark mr-3 ml-3" />
          {values?.windspeedMax}
          {speedUnit}
          <Eye className="w-6 h-6 bg-primary border-primary p-1 rounded-full text-background dark:text-backgroundDark mr-3 ml-3" />
          {values?.visibility}
          {distanceUnit}
          <Sun className="w-6 h-6 bg-primary border-primary p-1 rounded-full text-background dark:text-backgroundDark mr-3 ml-3" />
          {weather?.forecast.uv}UV
          <Droplet className="w-6 h-6 bg-primary border-primary p-1 rounded-full text-background dark:text-backgroundDark mr-3 ml-3" />
          {weather?.forecast?.humidity}% humidity
        </div>
      </div>
    );
  }, [weather?.forecast, weather?.country, weather?.region, weather?.updatedAt, units]);

  return (
    <WidgetWrapper initialValues={{ location }} canEdit={true} widget={widget}>
      <div className="h-full w-full flex flex-col justify-start">
        {loading && (
          <div className="w-full h-full flex items-center justify-center">
            <Loader size="md" />
          </div>
        )}
        {!loading && weatherSection}
        {!loading && showAstrology === "show" ? astroSection : null}
      </div>
    </WidgetWrapper>
  );
};

export default WeatherWidget;
