import React, { FC } from "react";
import { FieldArray } from "react-final-form-arrays";

import { FieldConfig } from "./Form";
import { TextInput } from "./TextInput";
import { Button } from "..";
import { RadioInput } from "./RadioInput";
import { isRequired } from "./utils";

export const EventInput: FC<FieldConfig<"array">> = ({ accessor }) => {
  return (
    <div className="flex flex-1 flex-col rounded-md  mb-4">
      <FieldArray name={accessor}>
        {({ fields }) => {
          return fields.map((name, index) => {
            return (
              <div
                key={`${name}-${index}`}
                className="flex flex-col mb-4 base-02 rounded-md p-3 shadow-sm "
              >
                <div className="flex flex-row w-full flex-1 mb-2">
                  <div className="flex flex-col flex-1 items-start mr-2">
                    <label className="block mb-0 text-sm font-medium dark:text-blue-100 text-gray-700">
                      Event Name
                    </label>
                    <div className="mt-2 flex flex-1 w-full">
                      <TextInput validate={isRequired} type="text" accessor={`${name}.label`} />
                    </div>
                  </div>

                  <div className="flex flex-col flex-1 items-start mr-2">
                    <label className="block mb-0 text-sm font-medium dark:text-blue-100 text-gray-700">
                      Date
                    </label>
                    <div className="mt-2 flex flex-1 w-full">
                      <TextInput validate={isRequired} type="date" accessor={`${name}.on`} />
                    </div>
                  </div>
                  <div className="flex flex-col flex-1 items-start">
                    <label className="block mb-0 text-sm font-medium dark:text-blue-100 text-gray-700">
                      Time
                    </label>
                    <div className="mt-2 flex flex-1 w-full">
                      <TextInput type="time" accessor={`${name}.at`} />
                    </div>
                  </div>
                </div>

                <div className="flex flex-row w-full flex-1 mb-2">
                  <div className="flex flex-col flex-1 items-start">
                    <label className="block mb-0 text-sm font-medium dark:text-blue-100 text-gray-700">
                      Description
                    </label>
                    <div className="mt-2 flex flex-1 w-full">
                      <TextInput type="textarea" accessor={`${name}.description`} />
                    </div>
                  </div>
                </div>

                <div className="flex flex-row w-full flex-1">
                  <div className="flex flex-1 flex-row items-center justify-start">
                    <RadioInput accessor={`${name}.repeats`} label="Never" value="never" />
                    <RadioInput accessor={`${name}.repeats`} label="Daily" value="daily" />
                    <RadioInput accessor={`${name}.repeats`} label="Weekly" value="weekly" />
                    <RadioInput accessor={`${name}.repeats`} label="Monthly" value="monthly" />
                    <RadioInput accessor={`${name}.repeats`} label="Yearly" value="yearly" />
                  </div>
                  <div className="flex flex-col items-end justify-end">
                    <Button
                      additionalClassnames="text-sm px-2"
                      type="button"
                      variant="secondary"
                      onClick={() => fields.remove(index)}
                      label="Remove Event"
                    />
                  </div>
                </div>
              </div>
            );
          });
        }}
      </FieldArray>
    </div>
  );
};
