import { ArrayFieldProps } from "../../Form/ArrayInput";
import { FieldConfig, FieldType } from "../../Form/Form";

export const config: { [key: string]: FieldConfig<FieldType>[] & ArrayFieldProps } = {
  "0": [
    {
      accessor: "title",
      title: "Title",
      description: "",
      type: "text",
    },
  ],
  "1": [
    {
      accessor: "description",
      title: "Description",
      description: "",
      type: "textarea",
    },
  ],
  "2": [
    {
      accessor: "show",
      title: "Display Options",
      description: "",
      type: "checkbox",
      values: [
        {
          accessor: "events",
          label: "Show Events",
          description: "",
          value: true,
          iconActive: "checkSquare",
          iconInactive: "square",
        },

        {
          accessor: "title",
          label: "Show Title & Description",
          description: "",
          value: true,
          iconActive: "checkSquare",
          iconInactive: "square",
        },
      ],
    },
  ],
  "3": [
    {
      accessor: "events",
      title: "Recurring Events",
      description: "",
      type: "array",
      value: "",
      component: "event",
    },
  ],
};
