import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MDVariant } from "../../types";

export interface Notification {
  variant: MDVariant;
  body: string;
  title: string;
  createdOn: string;
  id: string;
  autoHide?: boolean;
}

export type NotificationState = { [key: string]: Notification };

const initialState: NotificationState = {};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<Notification>) => {
      state[action.payload.id] = action.payload;
    },
    clear: (state, action: PayloadAction<Notification>) => {
      delete state[action.payload.id];
    },
  },
});

export default notificationsSlice.reducer;
export const { add, clear } = notificationsSlice.actions;
export const defaultNotifications = (
  title: string,
  body: string
): { [key in MDVariant]: Notification } => {
  const date = new Date().toISOString();
  return {
    success: {
      variant: "success",
      title: title,
      body: body,
      createdOn: date,
      id: date,
      autoHide: true,
    },
    danger: {
      variant: "danger",
      title: title,
      body: body,
      createdOn: date,
      id: date,
      autoHide: true,
    },
    info: {
      variant: "info",
      title: title,
      body: body,
      createdOn: date,
      id: date,
      autoHide: true,
    },
    default: {
      variant: "default",
      title: title,
      body: body,
      createdOn: date,
      id: date,
      autoHide: true,
    },
    secondary: {
      variant: "default",
      title: title,
      body: body,
      createdOn: date,
      id: date,
      autoHide: true,
    },
  };
};
