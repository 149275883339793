import React, { FC, useState, useEffect, useCallback } from "react";
import { UpdateWidget as UpdateWidgetType, UpdateWidgetUpdate } from "../../../types";
import { WidgetWrapper } from "../WidgetWrapper";
import classnames from "classnames";
import { User } from "react-feather";
import QRCode from "qrcode.react";
import { isToday, isWithinInterval, subMinutes } from "date-fns";

import { useAnimatePresence, Variants } from "use-animate-presence";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import "./styles.css";
const variants: Variants = {
  x: { from: -100, to: 0 },
};

const Update: FC<{
  index: number;
  emoji?: string;
  sender: string;
  update: string;
  createdOn: string;
  fresh?: boolean;
}> = ({ sender, update, fresh, emoji, index }) => {
  const animatedUpdate = useAnimatePresence({
    variants,
    initial: "visible",
    options: {
      stiffness: 600,
      mass: 1.5,
      damping: 40 - index,
    },
  });

  const wrapperClassnames = classnames(
    "relative flex flex-row p-3  max-w-full rounded-lg items-start justify-start text-background dark:text-backgroundDark",
    {
      "bg-success border-2 border-success w-full": fresh,
      "bg-secondary dark:bg-secondaryDark border-2 border-tertiary dark:border-tertiaryDark w-max": !fresh,
    }
  );

  const bodyClassnames = classnames("text-3xl break-words whitespace-pre-line max-w-full m-0");
  const iconClassnames = classnames("border-2 rounded-full h-10 w-10 p-1 flex flex-none", {
    "bg-success light border-2 border-success": fresh,
    "bg-secondary dark:bg-secondaryDark border-2 border-tertiary dark:border-tertiaryDark": !fresh,
  });

  return animatedUpdate.isRendered ? (
    <div ref={animatedUpdate.ref} className={wrapperClassnames}>
      {emoji ? (
        <span className="text-4xl bg-tertiary dark:bg-tertiaryDark shadow-md border-2 border-quaternary dark:border-quaternaryDark rounded-full m-0 w-10 h-10">
          {emoji}
        </span>
      ) : (
        <User className={iconClassnames} />
      )}
      <div className="px-4">
        {/* <span className={dateClassnames}>@{dateString}</span> */}
        <p className="m-0">{sender} says:</p>
        <p className={bodyClassnames}>{update}</p>
      </div>
    </div>
  ) : null;
};

const UpdateWidget: FC<UpdateWidgetType> = ({ ...widget }) => {
  const {
    outsideEnabled,
    showTodayOnly,

    freshnessThreshold,
    updates,
    updateUrl,
  } = widget;

  const [oldUpdates, setOldUpdates] = useState<UpdateWidgetUpdate[]>([]);
  const [freshUpdates, setFreshUpdates] = useState<UpdateWidgetUpdate[]>([]);

  const updateUpdates = useCallback(() => {
    if (!updates) {
      return;
    }
    const sortUpdates = (a: UpdateWidgetUpdate, b: UpdateWidgetUpdate) => {
      const aDate = new Date(a.createdOn);
      const bDate = new Date(b.createdOn);

      return aDate > bDate ? -1 : 1;
    };

    const filterUpdatesForToday = (update: UpdateWidgetUpdate) => {
      if (showTodayOnly === "today") {
        return isToday(new Date(update.createdOn));
      }
      return true;
    };

    const filterUpdatesFresh = (update: UpdateWidgetUpdate) => {
      if (freshnessThreshold) {
        const today = new Date();
        const todayMinusFreshnessSetting = subMinutes(today, Number(freshnessThreshold));

        return isWithinInterval(new Date(update.createdOn), {
          start: todayMinusFreshnessSetting,
          end: today,
        });
      }
      return true;
    };

    const allUpdates = [...(updates || [])].sort(sortUpdates).filter(filterUpdatesForToday);
    const newUpdates = allUpdates.filter(filterUpdatesFresh);
    const oldUpdates = allUpdates.filter((update) => !filterUpdatesFresh(update)).map((i) => i);

    setOldUpdates(oldUpdates);
    setFreshUpdates(newUpdates);
  }, [freshnessThreshold, showTodayOnly, updates]);

  useEffect(() => {
    let timeout: number;
    if (freshnessThreshold) {
      timeout = window.setTimeout(() => {
        updateUpdates();
      }, Number(freshnessThreshold) * 60000);
    }

    return () => {
      window.clearTimeout(timeout);
    };
  }, [widget.fields, updateUpdates, freshnessThreshold]);

  useEffect(() => {
    if (updates) {
      updateUpdates();
    }
  }, [updateUpdates, updates]);

  return (
    <WidgetWrapper widget={widget} canEdit={true} initialValues={{ updateUrl }}>
      <>
        {(oldUpdates || []).length === 0 && (freshUpdates || []).length === 0 && (
          <div className="flex flex-col p-6 py-10 items-center justify-center">
            <h3 className="text-sm mb-3 text-secondary dark:text-secondaryDark">
              No updates. Use the QR code to post something.
            </h3>
            <div className="p-3 rounded-lg w-auto m-auto">
              <QRCode className="m-auto rounded-md border-2" size={120} value={updateUrl} />
            </div>
          </div>
        )}
        {((oldUpdates || []).length > 0 || (freshUpdates || []).length > 0) && (
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
            <Masonry gutter="1rem">
              {(freshUpdates || []).map((update, updateIndex) => {
                return <Update index={updateIndex} key={updateIndex} {...update} fresh={true} />;
              })}
              {outsideEnabled === "enabled" && (
                <div className="p-3 rounded-full flex items-center justify-center w-36 h-36 border-2 bg-tertiary dark:bg-tertiaryDark m-auto">
                  <QRCode
                    className="m-auto rounded-md border-2 shadow-md"
                    size={120}
                    value={updateUrl}
                  />
                </div>
              )}
              {(oldUpdates || []).map((update, updateIndex) => {
                return <Update index={updateIndex} key={updateIndex} {...update} />;
              })}
            </Masonry>
          </ResponsiveMasonry>
        )}
      </>
    </WidgetWrapper>
  );
};

export default UpdateWidget;
