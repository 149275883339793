import React, { useMemo, useState, FC } from "react";
// import { Dialog, Transition } from "@headlessui/react";

import { CheckSquare, Square, ChevronRight, Home, PlusCircle } from "react-feather";

import { Button, SlideOutNav } from "..";
import { useDispatch, useSelector } from "../../utils";
import { getCurrentRoom, getRooms } from "../../views/admin/selectors";
import { getUser } from "../../views/auth/selectors";
import { setCurrent } from "../../views/admin/reducer";

import { Room } from "../../types";
import { SlideOutNavSection } from ".";
import { AddRoomModal } from "../../views/admin/addRoomModal";
import { ReactComponent as Logo } from "../../images/logo.svg";

import classnames from "classnames";
import { useLocation } from "react-router-dom";

export const RoomSelectSection: FC = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const location = useLocation();

  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const rooms = useSelector(getRooms);
  const currentRoom = useSelector(getCurrentRoom);
  const shouldDisplayRoomSelection = location.pathname === "/admin";

  const roomButtons = useMemo(() => {
    const handleRoomChange = (room: Room) => {
      const foundIndex = rooms?.findIndex((h) => h.id === room.id);
      if (foundIndex !== -1) {
        dispatch(setCurrent(foundIndex));
      }
    };

    return rooms.map((room) => {
      return (
        <Button
          additionalClassnames="w-full mx-0 justify-between rounded-none link text-sm py-2"
          variant="secondary"
          key={room?.id}
          onClick={() => {
            handleRoomChange(room);
            setNavOpen(false);
          }}
          icon={currentRoom?.id === room?.id ? <CheckSquare /> : <Square />}
          label={room?.name}
        />
      );
    });
  }, [dispatch, rooms, currentRoom?.id]);

  const iconClassnames = classnames("ml-3 transform rotate-0 transition-transform", {
    "rotate-180": navOpen,
  });

  return (
    <>
      <div className="flex flex-row items-center">
        <span
          onClick={(e) => {
            e.preventDefault();
            if (user?.uid && currentRoom?.id) {
              setNavOpen((prev) => !prev);
            } else {
              setModalOpen((prev) => !prev);
            }
          }}
          className="z-10 border-2 border-blue-100 dark:border-gray-600   m-0 align-middle  rounded-full"
        >
          <Logo width={36} height={36} />
        </span>

        {user?.uid && currentRoom?.id && shouldDisplayRoomSelection && (
          <Button
            label={currentRoom.name}
            onClick={(e) => {
              e.preventDefault();
              setNavOpen((prev) => !prev);
            }}
            icon={<ChevronRight className={iconClassnames} />}
            additionalClassnames="-ml-4 z-0 pl-7 dark"
          />
        )}

        {user?.uid && !currentRoom?.id && shouldDisplayRoomSelection && (
          <>
            <Button
              label="Add Room"
              onClick={() => {
                setModalOpen(true);
              }}
              icon={<PlusCircle />}
              variant="success"
              additionalClassnames="-ml-4 z-0 pl-7"
            />
            <AddRoomModal
              open={modalOpen}
              loading={false}
              handleClose={() => {
                setModalOpen(false);
              }}
            />
          </>
        )}
      </div>
      <SlideOutNav
        open={navOpen}
        onClose={() => {
          setNavOpen(false);
        }}
        from="left"
      >
        <>
          <SlideOutNavSection
            title="Your Rooms"
            subtitle="All rooms created by you"
            icon={<Home />}
          >
            <>
              {roomButtons}

              <Button
                label="Add Room"
                onClick={() => {
                  setModalOpen(true);
                }}
                icon={<PlusCircle />}
                variant="success"
                additionalClassnames="w-full mx-0 justify-between rounded-none link outline text-sm py-2"
              />
            </>
          </SlideOutNavSection>
          <AddRoomModal
            open={modalOpen}
            loading={false}
            handleClose={() => {
              setModalOpen(false);
            }}
          />
        </>
      </SlideOutNav>
      {/* 
      <Transition.Root show={navOpen} as={Fragment}>
        <Dialog
          static
          as="div"
          className="fixed inset-0 overflow-hidden z-50 "
          open={navOpen}
          // style={{ top: "56px" }}
          onClose={() => {
            setNavOpen(false);
          }}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-25" />
            </Transition.Child>
            <div className="fixed inset-y-0 left-0 pr-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-300"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="relative w-screen md:w-72 lg:w-80">
                  <button
                    className="opacity-0 absolute bottom-0 left-0 rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={() => setNavOpen(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <X className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="h-5/6 flex flex-col base-01 shadow-xl overflow-auto">
                    <div className="relative flex-1">
                      <div className="absolute inset-0 ">
                        <SlideOutNavSection
                          title="Your Rooms"
                          subtitle="All rooms created by you"
                          icon={<Home />}
                        >
                          <>
                            {roomButtons}

                            <Button
                              label="Add Room"
                              onClick={() => {
                                setModalOpen(true);
                              }}
                              icon={<PlusCircle />}
                              variant="success"
                              additionalClassnames="w-full mx-0 justify-between rounded-none link outline text-sm py-2"
                            />
                          </>
                        </SlideOutNavSection>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>

          <AddRoomModal
            open={modalOpen}
            loading={false}
            handleClose={() => {
              setModalOpen(false);
            }}
          />
        </Dialog>
      </Transition.Root> */}
    </>
  );
};
