import React, { FC, ReactElement, Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { X } from "react-feather";
import classnames from "classnames";

interface Props {
  open: boolean;
  onClose: () => void;
  children: ReactElement | ReactElement[];
  from: "left" | "right";
}

const fromLeftTransitionProps = {
  as: Fragment,
  enter:
    "transform transition-transform transition-opacity ease-in-out duration-300 sm:duration-300",
  enterFrom: "-translate-x-full opacity-0",
  enterTo: "translate-x-0 opacity-1",
  leave:
    "transform transition-transform transition-opacity ease-in-out duration-300 sm:duration-300",
  leaveFrom: "translate-x-0 opacity-1",
  leaveTo: "-translate-x-full opacity-0",
};

const fromRightTransitionProps = {
  as: Fragment,
  enter:
    "transform transition-transform transition-opacity ease-in-out duration-300 sm:duration-300",
  enterFrom: "translate-x-full opacity-0",
  enterTo: "translate-x-0 opacity-1",
  leave:
    "transform transition-transform transition-opacity ease-in-out duration-300 sm:duration-300",
  leaveFrom: "translate-x-0 opacity-1",
  leaveTo: "translate-x-full opacity-0",
};

const SlideOutNav: FC<Props> = ({ open, onClose, children, from }) => {
  const transitionProps = from === "left" ? fromLeftTransitionProps : fromRightTransitionProps;
  const childClassnames = classnames("fixed inset-y-0 max-w-full flex", {
    "right-5 pl-10": from === "right",
    "left-5 pr-10": from === "left",
  });
  const closeButtonClassnames = classnames("absolute top-0  z-50 rounded-full", {
    "right-0 -mr-10": from === "left",
    "left-0 -ml-10": from === "right",
  });

  return (
    <Transition.Root show={open} as={Fragment} unmount={true}>
      <Dialog
        static
        as="div"
        className="fixed inset-0 overflow-hidden z-50 "
        open={open}
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-800 dark:bg-black bg-opacity-75 transition-opacity z-25" />
          </Transition.Child>
          <div className={childClassnames}>
            <Transition.Child {...transitionProps}>
              <div className="relative w-screen md:w-72 lg:w-80 my-10 ">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className={closeButtonClassnames}>
                    <button className="rounded-full p-2 btn btn-secondary" onClick={onClose}>
                      <span className="sr-only">Close panel</span>
                      <X className="h-4 w-4" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>

                <div className="h-full flex flex-col base-01 rounded-lg shadow-xl overflow-auto">
                  <div className="relative flex-1">
                    <div className="absolute inset-0 ">{children}</div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SlideOutNav;
