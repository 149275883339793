import { FieldConfig, FieldType } from "../../Form/Form";
import { isRequired, isValidJson } from "../../Form/utils";

export const config: { [key: string]: FieldConfig<FieldType>[] } = {
  "0": [
    {
      accessor: "text",
      title: "Text",
      description: "",
      type: "text",
      validate: isRequired,
    },
  ],
  "1": [
    {
      accessor: "size",
      title: "Size",
      description: "",
      type: "number",
    },
    {
      accessor: "font",
      title: "Font",
      description: "",
      type: "select",
      values: [
        { label: "Arial", value: "Arial, sans-serif" },
        { label: "Arial Black", value: "Arial Black, sans-serif" },
        { label: "Times New Roman", value: "Times New Roman, serif" },
        { label: "Courier New", value: "Courier New, monospace" },
        { label: "Courier", value: "Courier, monospace" },
        { label: "Verdana", value: "Verdana, sans-serif" },
        { label: "Georgia", value: "Georgia, serif" },
        { label: "Palatino", value: "Palatino, serif" },
        { label: "Garamond", value: "Garamond, serif" },
        { label: "Impact", value: "Impact, sans-serif" },
        { label: "Comic Sans", value: "Comic Sans, sans-serif" },
        { label: "Helvetica", value: "Helvetica, sans-serif" },
      ],
    },
  ],
  "2": [
    {
      accessor: "color",
      title: "Color",
      description: "",
      type: "color",
    },
    {
      accessor: "backgroundColor",
      title: "Background Color",
      description: "",
      type: "color",
    },
  ],
  "3": [
    {
      accessor: "align",
      title: "Align Text",
      description: "",
      type: "radio",
      value: "center",
      values: [
        { label: "Left", description: "", value: "left", icon: "alignLeft" },
        { label: "Center", description: "", value: "center", icon: "alignCenter" },
        { label: "Right", description: "", value: "right", icon: "alignRight" },
      ],
    },
  ],
  "4": [{ accessor: "css", title: "Custom CSS", type: "textarea", validate: isValidJson }],
};
