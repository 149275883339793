import React, { FC } from "react";
import { ReactComponent as EmptyImage } from "../../images/empty_bg_01.svg";

interface Props {
  title: string;
  description?: string;
}

const EmptyState: FC<Props> = ({ title, description }) => (
  <div className="w-full flex flex-col p-16 base-01 items-center justify-center">
    <EmptyImage className="w-2/4 h-2/4" />

    <h3 className="text-lg ">{title}</h3>
    <p className="text-sm ">{description}</p>
  </div>
);

export default EmptyState;
