import RGL from "react-grid-layout";
import { Widget } from "../../types";

export const watchedWidgetProperties: Partial<keyof RGL.Layout>[] = ["x", "y", "w", "h", "moved"];

export const isOutOfBounds = (widget: RGL.Layout, gridWidth: number) => {
  return widget.x + widget.w > gridWidth;
};

export const hasWidgetChanged = (prev: RGL.Layout, current: RGL.Layout) => {
  return watchedWidgetProperties.reduce((acc, curr) => {
    const prevVal = prev[curr];
    const currentVal = current[curr];

    if (prevVal !== currentVal) {
      acc = true;
    }

    return acc;
  }, false);
};

export const haveAnyWidgetsChanged = (prev: RGL.Layout[], current: RGL.Layout[]) => {
  return current.reduce((acc, curr) => {
    const previousWidget = prev.find((f) => f.i === curr.i);

    if (previousWidget) {
      const hasChanged = hasWidgetChanged(previousWidget, curr);
      if (hasChanged) {
        acc = true;
      }
    }

    return acc;
  }, false);
};

export const sanitizeWidget = (widget: Widget) => {
  const { ...rest } = widget;
  return Object.entries(rest).reduce((acc, [key, val]) => {
    if (val && key !== "fields") {
      return { ...acc, [key]: val };
    } else {
      return { ...acc };
    }
  }, {});
};

export const sanitizeLayoutItem = (widget: RGL.Layout) => {
  return Object.entries(widget)
    .filter(([_, val]) => val !== undefined)
    .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {}) as RGL.Layout;
};

export const sanitizeLayout = (widgets: RGL.Layout[]) => widgets.map((w) => sanitizeLayoutItem(w));
