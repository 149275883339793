import React, { FC } from "react";
import { PlusCircle } from "react-feather";
import { useForm } from "react-final-form";
import { EventInput } from "./EventInput";

import { Button } from "..";
import { FieldConfig } from "./Form";
import { RssAdditionalKeyInput } from "./RssAdditionalKeyInput";

export type ComponentType = "event" | "rssAdditionalKeys";

const componentMap: { [key in ComponentType]: FC<any> } = {
  event: EventInput,
  rssAdditionalKeys: RssAdditionalKeyInput,
};
export interface ArrayFieldProps {
  onAdd?: (accessor: string) => void;
  component?: ComponentType;
}

export const ArrayInput: FC<FieldConfig<"array"> & ArrayFieldProps> = ({ accessor, component }) => {
  const form = useForm();
  const {
    mutators: { push },
  } = form;
  if (!component) {
    return null;
  }
  return (
    <div className="flex flex-col flex-1">
      {componentMap[component]({ accessor })}

      <Button
        type="button"
        additionalClassnames="justify-self-end w-max m-0"
        onClick={() => {
          push(accessor);
        }}
        label="Add More"
        variant="info"
        icon={<PlusCircle />}
      />
    </div>
  );
};
