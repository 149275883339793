import React, { FC } from "react";
import { StaticTextWidget as StaticTextWidgetType } from "../../../types";
import { WidgetWrapper } from "../WidgetWrapper";

const StaticTextWidget: FC<StaticTextWidgetType> = ({ ...widget }) => {
  const { text, size, color, font, css, align, backgroundColor } = widget;

  const styleObj = {
    fontSize: `${size}px`,
    color: color,
    fontFamily: font,
    textAlign: align,
    ...JSON.parse(String(css || "{}")),
  };
  const wrapperStyleObj =
    backgroundColor !== "transparent" && backgroundColor ? { backgroundColor } : {};
  return (
    <WidgetWrapper initialValues={{}} canEdit={true} widget={widget}>
      <div
        className="flex items-center justify-center h-full rounded-lg px-3"
        style={wrapperStyleObj}
      >
        <p className="m-0 w-full" style={styleObj}>
          {text}
        </p>
      </div>
    </WidgetWrapper>
  );
};

export default StaticTextWidget;
