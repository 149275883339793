import React, { FC } from "react";
import firebase from "firebase/app";
import FirebaseAuth from "react-firebaseui/FirebaseAuth";

import { firebaseUiConfig } from "../../config";
import { useSelector } from "../../utils";

import { getUser } from "./selectors";
import { Redirect } from "react-router";

import "./firebaseui.css";

const Auth: FC = () => {
  const user = useSelector(getUser);

  if (user?.uid) {
    return <Redirect to="/admin" />;
  }

  return (
    <div className="flex flex-row flex-1 h-full">
      <div className="flex flex-1  rounded-md p-3">
        <h1>auth</h1>
      </div>
      <div className="flex flex-1 max-w-md items-center justify-center p-3">
        <FirebaseAuth uiConfig={firebaseUiConfig} firebaseAuth={firebase.auth()} />
      </div>
    </div>
  );
};

export default Auth;
