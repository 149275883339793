/* This example requires Tailwind CSS v2.0+ */
import { FC, Fragment, ReactElement } from "react";
import { Listbox, Transition } from "@headlessui/react";

import { CheckCircle, ChevronDown } from "react-feather";

import { useField } from "react-final-form";
import { FieldConfig } from "./Form";
export interface SelectInputOption {
  value: string;
  label: string;
  icon?: ReactElement;
}

interface Props {
  options?: SelectInputOption[];
  onChange: (value: SelectInputOption) => void;
  value: SelectInputOption;
  label?: string;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const Select: FC<Props> = ({ options, onChange, value, label }) => {
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          {label && (
            <Listbox.Label className="block text-sm font-medium dark:text-blue-100 text-gray-500 mb-2">
              {label}
            </Listbox.Label>
          )}
          <div className="relative w-full z-10">
            <Listbox.Button className="relative w-full dark:text-blue-100 dark:bg-gray-800 bg-white border border-gray-300 dark:border-gray-700 rounded-md shadow-sm pl-3 pr-10 py-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm z-0">
              <span className="flex items-center">
                {/* <img src={selected.avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" /> */}
                <span className="block truncate text-lg">{value.label}</span>
              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute mt-1 w-full  dark:text-blue-100 dark:bg-gray-700 bg-gray-100 shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                {(options || []).map((option) => (
                  <Listbox.Option
                    key={`${option.value}-${option.label}`}
                    className="btn btn-secondary px-1 text-sm relative"
                    value={option}
                  >
                    {({ selected }) => {
                      return (
                        <>
                          <div className="flex items-center">
                            {/* <img src={option.avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" /> */}
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {option.label}
                            </span>
                          </div>

                          {selected ? (
                            <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                              <CheckCircle className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      );
                    }}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export const SelectInput: FC<FieldConfig<"select">> = ({ type, accessor, validate, values }) => {
  const field = useField(accessor, { type, validate });

  if (!values) {
    return <p>No Values Provided</p>;
  }

  const handleSelectChange = (option: SelectInputOption) => field.input.onChange(option.value);
  const current = values?.find((f) => f.value === field.input.value) as SelectInputOption;

  return (
    <Select
      value={current || values[0]}
      options={values as SelectInputOption[]}
      onChange={handleSelectChange}
    />
  );
};
