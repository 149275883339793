import { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { LoadingOverlay, Nav } from "./components";

import { routes } from "./config";

import "./assets/index.css";

function App() {
  return (
    <div className="dark:bg-gray-900 min-h-full flex flex-col h-full max-h-screen">
      <Router>
        <Nav />

        <Suspense fallback={<LoadingOverlay />}>
          <div className="main-container">
            <Switch>
              {routes.map((route) => (
                <Route exact component={route.component} path={route.path} key={route.path} />
              ))}
            </Switch>
          </div>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
