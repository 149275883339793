import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../types";

import { dbGetUser } from "./api";

export interface UserState {
  loading: boolean;
  user?: User;
}

const initialState: UserState = { loading: false, user: {} };

const affiliateMembersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = { ...state.user, ...action.payload };
    },
    clearUser: (state) => {
      state.user = initialState.user;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(dbGetUser.fulfilled, (state, action: PayloadAction<User>) => {
      state.user = { ...state.user, ...action.payload };
    });
  },
});

export const { setUser, clearUser } = affiliateMembersSlice.actions;
export default affiliateMembersSlice.reducer;
