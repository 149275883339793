import React, { FC } from "react";
import { useField } from "react-final-form";
import { ColorPicker } from "../ColorPicker";

import { FieldConfig } from "./Form";

const themeColorsNames = [
  "--primary",
  "--primaryDark",
  "--secondary",
  "--secondaryDark",
  "--tertiary",
  "--tertiaryDark",
  "--quaternary",
  "--quaternaryDark",

  "--background",
  "--backgroundDark",
  "--widgetBackground",
  "--widgetBackgroundDark",
];

export const defaultColors: string[] = [
  "#1ABC9C",
  "#16A085",
  "#2ECC71",
  "#27AE60",
  "#3498DB",
  "#2980B9",
  "#9B59B6",
  "#8E44AD",
  "#34495E",
  "#2C3E50",
  "#F1C40F",
  "#F39C12",
  "#E67E22",
  "#D35400",
  "#E74C3C",
  "#C0392B",
  "#ECF0F1",
  "#BDC3C7",
  "#95A5A6",
  "#7F8C8D",
  "#FFFFFF",
  "transparent",
];

export const ColorInput: FC<FieldConfig<"color">> = ({ type, accessor, validate }) => {
  const field = useField(accessor, { type, validate });
  const themeColors = themeColorsNames.map((varName) =>
    getComputedStyle(document.documentElement).getPropertyValue(varName)
  );

  return (
    <ColorPicker
      current={field.input.value}
      onChange={(newColor: string) => field.input.onChange(newColor)}
      presets={[...themeColors, ...defaultColors]}
    />
  );
};
