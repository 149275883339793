export const isRequired = (value: string) => {
  return value && value.length > 0 ? undefined : "Required";
};
export const mustBeNumber = (value: number) => (isNaN(value) ? "Must be a number" : undefined);

export const minValue = (min: number) => (value: number) =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;

export const maxValue = (max: number) => (value: number) =>
  isNaN(value) || value <= max ? undefined : `Should be less than ${max}`;

export const isImage = (url: string) => {
  const regex = new RegExp(
    // eslint-disable-next-line
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
  );

  const match = url.match(regex);
  // console.log(match);

  return match ? undefined : "Must be a valid image url";
};

export const isValidJson = (value: string) => {
  if (!value || value === "") {
    return undefined;
  }
  try {
    JSON.parse(value);
  } catch (e) {
    return "Must be valid JSON";
  }
  return undefined;
};

export const composeValidators = (...validators: any) => (value: any) =>
  validators.reduce((error: any, validator: any) => error || validator(value), undefined);
