import { FieldConfig, FieldType } from "../../Form/Form";
import { isRequired } from "../../Form/utils";

export const config: { [key: string]: FieldConfig<FieldType>[] } = {
  "0": [
    {
      accessor: "location",
      title: "Location",
      type: "text",
      value: "",
      validate: isRequired,
    },
  ],
  "1": [
    {
      accessor: "showAstrology",
      title: "Show Sun/Moon Rise/Set",
      type: "radio",
      values: [
        {
          label: "Show",
          value: "show",
          icon: "eye",
        },
        {
          label: "Hide",
          description: "Show all updates that exists for this widget.",
          value: "hide",
          icon: "eyeOff",
        },
      ],
    },
    {
      accessor: "units",
      title: "Units of Measurement",
      type: "radio",
      values: [
        {
          label: "Metric",
          value: "metric",
          icon: "square",
        },
        {
          label: "Imperial",
          description: "Show all updates that exists for this widget.",
          value: "imperial",
          icon: "triangle",
        },
      ],
    },
  ],
};
