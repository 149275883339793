import { ReactElement } from "react";
import { IconType } from "./Form";
import {
  Eye,
  EyeOff,
  Mic,
  MicOff,
  Square,
  CheckSquare,
  Maximize2,
  Minimize2,
  AlignLeft,
  AlignCenter,
  AlignRight,
  Rewind,
  Play,
  Triangle,
  ArrowDown,
  ArrowUp,
  ArrowLeft,
  ArrowRight,
  Circle,
} from "react-feather";

export const iconMap: { [key in IconType]: ReactElement } = {
  eye: <Eye />,
  eyeOff: <EyeOff />,
  mic: <Mic />,
  micOff: <MicOff />,
  square: <Square />,
  checkSquare: <CheckSquare />,
  maximize: <Maximize2 />,
  minimize: <Minimize2 />,
  alignLeft: <AlignLeft />,
  alignCenter: <AlignCenter />,
  alignRight: <AlignRight />,
  rewind: <Rewind />,
  play: <Play />,
  triangle: <Triangle />,
  arrowDown: <ArrowDown />,
  arrowUp: <ArrowUp />,
  arrowLeft: <ArrowLeft />,
  arrowRight: <ArrowRight />,
  circle: <Circle />,
};
