import React, { FC } from "react";
import { useField } from "react-final-form";

import { FieldConfig } from "./Form";

const INPUT_CLASSNAME =
  "dark:text-blue-100 dark:bg-gray-800  focus:ring-blue-600 focus:border-blue-600  block w-full rounded-md  dark:border-gray-600 border-gray-300 text-lg";

export const TextInput: FC<FieldConfig<"time" | "text" | "number" | "textarea" | "date">> = ({
  type,
  accessor,
  validate,
  disabled,
}) => {
  const field = useField(accessor, { type, validate });

  return (
    <div className="flex flex-1 flex-col">
      {type === "textarea" ? (
        <textarea className={INPUT_CLASSNAME} {...field.input} disabled={disabled}></textarea>
      ) : (
        <input className={INPUT_CLASSNAME} {...field.input} disabled={disabled} />
      )}
      {field.meta.error && field.meta.touched && (
        <span className="text-red-500 text-sm">{field.meta.error}</span>
      )}
    </div>
  );
};
