import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

export const updateRssFeed = async ({
  uid,
  roomId,
  widgetId,
  url,
}: {
  uid: string;
  roomId: string;
  widgetId: string;
  url: string;
}) => {
  try {
    const fn = firebase.functions().httpsCallable("mydom-getRssFeed");
    const { data } = await fn({ uid, roomId, widgetId, url });
    return data;
  } catch (e) {
    throw new Error(e);
  }
};
