import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "../../utils";
import { getUser } from "../../views/auth/selectors";
import { Link } from "react-router-dom";
import { Button, SlideOutNav } from "..";
import { Menu, User } from "react-feather";
import { SlideOutNavSection } from ".";
import { signoutUser } from "../../views/auth/api";
import classnames from "classnames";
import { ReactComponent as Logo } from "../../images/logo.svg";

export const ProfileSection: FC = () => {
  const [navOpen, setNavOpen] = useState(false);

  const user = useSelector(getUser);
  const dispatch = useDispatch();

  const iconClassnames = classnames("transform rotate-0 transition-transform", {
    "rotate-90": navOpen,
  });

  return (
    <>
      <div className="flex flex-row items-center ">
        <span
          onClick={() => {
            setNavOpen((prev) => !prev);
          }}
          className="z-10 border-2 border-blue-100 dark:border-gray-600  large-letter-logo font-bold  m-0 align-middle btn-secondary rounded-full"
        >
          <Menu className={iconClassnames} size={20} />
        </span>
      </div>

      <SlideOutNav
        open={navOpen}
        onClose={() => {
          setNavOpen(false);
        }}
        from="right"
      >
        <>
          {user?.uid && (
            <SlideOutNavSection title={user?.email ? user.email : "Anonymous User"} icon={<User />}>
              <>
                <Link
                  className="btn btn-secondary w-full mx-0 justify-between rounded-none link text-sm py-2"
                  onClick={() => {
                    setNavOpen(false);
                  }}
                  to="/admin"
                >
                  My Rooms
                </Link>
                <Button
                  label="Sign out"
                  onClick={() => {
                    dispatch(signoutUser());
                  }}
                  additionalClassnames="w-full mx-0 justify-between rounded-none link text-sm py-2"
                  variant="secondary"
                />
              </>
            </SlideOutNavSection>
          )}

          <SlideOutNavSection title="myDom" icon={<Logo width={24} height={24} />}>
            <>
              {!user?.uid && (
                <>
                  <Link
                    className="btn btn-secondary w-full mx-0 justify-between rounded-none link text-sm py-2"
                    onClick={() => {
                      setNavOpen(false);
                    }}
                    to="/auth"
                  >
                    Sign in
                  </Link>
                </>
              )}
              <Link
                className="btn btn-secondary w-full mx-0 justify-between rounded-none link text-sm py-2"
                onClick={() => {
                  setNavOpen(false);
                }}
                to="/"
              >
                Home
              </Link>
              {/* <Link
                className="btn btn-secondary w-full mx-0 justify-between rounded-none link text-sm py-2"
                onClick={() => {
                  setNavOpen(false);
                }}
                to="/pricing"
              >
                Pricing
              </Link>

              <Link
                className="btn btn-secondary w-full mx-0 justify-between rounded-none link text-sm py-2"
                onClick={() => {
                  setNavOpen(false);
                }}
                to="/about"
              >
                About
              </Link>

              <Link
                className="btn btn-secondary w-full mx-0 justify-between rounded-none link text-sm py-2"
                onClick={() => {
                  setNavOpen(false);
                }}
                to="/help"
              >
                Help
              </Link> */}
            </>
          </SlideOutNavSection>
        </>
      </SlideOutNav>
    </>
  );
};
