import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { RssWidget as RssWidgetType, Widget } from "../../../types";
import { WidgetWrapper } from "../WidgetWrapper";
import { differenceInMilliseconds } from "date-fns";
import { updateRssFeed } from "./api";
import { Loader } from "../../LoadingOverlay";
import styles from "./styles.module.css";

export interface WidgetComponentProps {
  widget: Widget;
}

const RssWidget: FC<RssWidgetType> = ({ ...widget }) => {
  const { url, refreshInterval, rowCount, feed, uid, roomId, additionalKeys, display } = widget;
  const refreshIntervalMinutes = (refreshInterval || 30) * 60000;
  const [loading, setLoading] = useState(false);

  const refreshForecast = useCallback(async () => {
    setLoading(true);
    try {
      await updateRssFeed({ url, uid, roomId, widgetId: widget.id });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [url, uid, roomId, widget.id]);

  useEffect(() => {
    const lastUpdateTime = new Date(feed?.updatedAt || "");
    const timeSinceLastUpdate = differenceInMilliseconds(new Date(), lastUpdateTime);
    if (!feed || timeSinceLastUpdate > refreshIntervalMinutes || feed.feedUrl !== url) {
      refreshForecast();
    }
  }, [url, feed, refreshIntervalMinutes, refreshForecast]);

  useEffect(() => {
    let timeout: number;

    timeout = window.setTimeout(() => {
      refreshForecast();
    }, refreshIntervalMinutes);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [refreshForecast, refreshIntervalMinutes]);

  const rssItems = useMemo(() => {
    return (feed?.data.items || []).slice(0, rowCount).map((item: any, index: number) => {
      const htmlFields = (additionalKeys || []).map((key) => {
        return (
          <div
            className={styles.rssHtmlFields}
            dangerouslySetInnerHTML={{ __html: item[key.key] }}
          />
        );
      });

      return (
        <div
          className="p-1 px-3 mb-3 rounded-md text-primary dark:even:bg-primaryDark even:bg-primary dark:even:text-backgroundDark even:text-background"
          key={index}
        >
          <p className="text-lg">{item.title}</p>
          {htmlFields}
        </div>
      );
    });
  }, [additionalKeys, feed?.data.items, rowCount]);

  const updatedDate = feed?.updatedAt ? new Date(feed?.updatedAt) : new Date();

  const updatedDay = updatedDate.toLocaleDateString();
  const updatedTime = updatedDate.toLocaleTimeString();

  return (
    <WidgetWrapper initialValues={{ additionalKeys }} canEdit={true} widget={widget}>
      <>
        {loading && (
          <div className="w-full h-full flex items-center justify-center">
            <Loader size="md" />
          </div>
        )}

        {!loading && (
          <div className="flex flex-col">
            {(display?.title || display?.description) && (
              <div className="flex flex-row items-center border-b border-secondary dark:border-secondaryDark pb-5 mb-5">
                {display?.title && feed?.data?.image && (
                  <img
                    className={styles.titleImage}
                    src={feed.data.image.url}
                    alt={feed.data.image.title}
                  />
                )}
                <div className="flex flex-col truncate">
                  {display?.title && (
                    <>
                      {feed?.updatedAt && (
                        <p className="text-xs text-primary">
                          Updated on {updatedDay} at {updatedTime}
                        </p>
                      )}
                      <p className="text-primary text-3xl mb-0 font-bold ">{feed?.data.title}</p>
                    </>
                  )}
                  {display?.description && (
                    <>
                      <p className="text-lg text-primary truncate">{feed?.data?.description}</p>
                      <p className="text-sm text-secondary ">{feed?.data?.link}</p>
                    </>
                  )}
                </div>
              </div>
            )}

            {rssItems}
          </div>
        )}
        {/* <p>
          Rss {url} {refreshInterval} {rowCount}
        </p> */}
      </>
    </WidgetWrapper>
  );
};

export default RssWidget;
