import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
// import "firebase/auth";

// import { Room } from "../../types";
// import { defaultNotifications, add } from "../../store/reducers";

// export const dbAddRoom = createAsyncThunk(
//   "room/api/add",
//   async ({ uid, room }: { uid: string; room: Room }, { dispatch }) => {
//     const ref = await firebase.firestore().collection("users").doc(uid).collection("rooms").doc();

//     await ref.set({ ...room, id: ref.id });

//     dispatch(add(defaultNotifications("Room Added", `${room.name} was added.`)["success"]));
//     return { ...room, id: ref.id };
//   }
// );

export const updateWeather = async ({
  uid,
  roomId,
  widgetId,
  location,
}: {
  uid: string;
  roomId: string;
  widgetId: string;
  location: string;
}) => {
  try {
    const fn = firebase.functions().httpsCallable("weatherapi-updateWeather");
    const { data } = await fn({ uid, roomId, widgetId, location });
    return data;
  } catch (e) {
    throw new Error(e);
  }
};
