import React, { FC, ReactElement } from "react";
import classnames from "classnames";

import { useSelector } from "../../utils";
import { getNotificationsArray } from "../../store/selectors";
import { MDVariant } from "../../types";

import { Notification } from "../../store/reducers/notifications";

import { CheckCircle, AlertCircle, Info, HelpCircle } from "react-feather";
import { Transition } from "@headlessui/react";

const Toast: FC<Notification> = ({ variant, title, body }) => {
  const wrapperClassnames = classnames(
    "absolute right-0 w-full  rounded-lg h-full flex flex-row items-center overflow-hidden",
    {
      "bg-danger dark border border-danger": variant === "danger",
      "bg-default dark border border-default": variant === "default" || !variant,
      "bg-success dark border border-success": variant === "success",
      "bg-warning dark border border-info": variant === "info",
    }
  );
  const iconWrapperClassnames = classnames("p-2 mr-2 flex items-start justify-center", {
    "bg-danger bg-danger": variant === "danger",
    "bg-default": variant === "default" || !variant,
    "bg-success": variant === "success",
    "bg-warning": variant === "info",
  });

  const iconClassnames = classnames("w-7 h-7 p-1 rounded-full", {
    "bg-danger dark bg-danger": variant === "danger",
    "bg-default dark": variant === "default" || !variant,
    "bg-success dark": variant === "success",
    "bg-warning dark": variant === "info",
  });

  const titleClassnames = classnames("font-medium mr-5 text-lg", {
    "text-danger dark": variant === "danger",
    "text-default dark": variant === "default" || !variant,
    "text-success dark": variant === "success",
    "text-info dark": variant === "info",
  });

  const iconMap: { [key in MDVariant]: ReactElement } = {
    danger: <AlertCircle className={iconClassnames} />,
    success: <CheckCircle className={iconClassnames} />,
    default: <Info className={iconClassnames} />,
    secondary: <Info className={iconClassnames} />,
    info: <HelpCircle className={iconClassnames} />,
  };

  return (
    <div className={wrapperClassnames}>
      <div className={iconWrapperClassnames}>{iconMap[variant]}</div>
      <p className={titleClassnames}>{title}</p>
      <span className="text-sm">{body}</span>
    </div>
  );
};

const ToastWrapper = () => {
  const notifications = useSelector(getNotificationsArray);

  return (
    <div className="z-0 w-full h-full overflow-hidden">
      <Transition
        className="h-full w-full"
        appear={true}
        show={!!notifications}
        enter="transition-transform duration-300"
        enterFrom="transform translate-x-full"
        enterTo="transform translate-x-0"
        leave="transition-transform duration-300"
        leaveFrom="transform translate-x-0"
        leaveTo="transform -translate-x-full"
      >
        <Toast {...notifications} />
      </Transition>
    </div>
  );
};

export default ToastWrapper;
