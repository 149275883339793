import React, { FC, useEffect, useState, useRef } from "react";
import Clock from "react-clock";
import { addSeconds } from "date-fns";
import { ClockWidget as ClockWidgetType } from "../../../types";
import { WidgetWrapper } from "../WidgetWrapper";
import "./style.css";
const { utcToZonedTime } = require("date-fns-tz");

const ClockWidget: FC<ClockWidgetType> = ({ ...widget }) => {
  const [value, setValue] = useState<Date>();

  const timer = useRef(0);

  const { timezone, clockLabel } = widget;

  useEffect(() => {
    if (timezone) {
      const date = new Date();
      const timezoned = utcToZonedTime(date, timezone || "America/New_York");

      setValue(timezoned);

      timer.current = window.setInterval(() => {
        setValue((prev) => addSeconds(prev || timezoned, 1));
      }, 1000);
    }

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [timezone]);

  return (
    <WidgetWrapper initialValues={{ timezone, clockLabel }} canEdit={true} widget={widget}>
      <div className="h-full w-full flex flex-col items-center justify-center relative">
        {value && <Clock renderSecondHand={true} value={value} />}
        <span className="truncate text-primary font-bold font-mono text-sm uppercase w-full text-center mt-3">
          {clockLabel ? clockLabel : timezone}
        </span>
      </div>
    </WidgetWrapper>
  );
};

export default ClockWidget;
