import { createSelector } from "reselect";
import { RootState } from "../../store";
import { options } from "../../config/themes/themeSelectOptions";

export const data = (state: RootState) => state.room;

export const getIsLoading = createSelector([data], (rooms) => rooms.loading);
export const getCurrentIndex = createSelector([data], (data) => data.currentIndex);

export const getRooms = createSelector([data], (rooms) => rooms?.all || []);

export const getCurrentRoom = createSelector([data], (rooms) => rooms.all?.[rooms.currentIndex]);
export const getCurrentCache = createSelector([data], (rooms) => rooms.cache?.[rooms.currentIndex]);

export const getHasRooms = createSelector([getRooms], (rooms) => (rooms || []).length > 0);

export const getRoomsAsSelectOptions = createSelector([getRooms], (rooms) =>
  rooms?.map((room) => ({ id: room.id, value: room.id, label: room.name }))
);
export const getCurrentRoomAsSelectOption = createSelector([getCurrentRoom], (room) => ({
  id: room?.id,
  value: room?.id,
  label: room?.name,
}));

export const getCurrentLayout = createSelector([getCurrentRoom], (room) => room.layout);
export const getCurrentLayoutCache = createSelector([getCurrentCache], (cache) => cache?.layout);

export const getCurrentLayoutMap = createSelector([getCurrentLayout], (layout) => layout.map);
export const getCurrentRGLLayout = createSelector([getCurrentLayout], (layout) =>
  layout.rglLayout.filter((f) => {
    return layout.map[f.i];
  })
);

export const getCurrentLayoutSettings = createSelector(
  [getCurrentLayout],
  (layout) => layout?.settings
);

export const getIsLayoutDirty = createSelector([data, getCurrentIndex], ({ all, cache }, index) => {
  const oldData = cache[index];
  const newData = all[index];

  const mapDirty = JSON.stringify(oldData.layout.map) !== JSON.stringify(newData.layout.map);
  const rglDirty =
    JSON.stringify(oldData.layout.rglLayout) !== JSON.stringify(newData.layout.rglLayout);
  const settingsDirty =
    JSON.stringify(oldData.layout.settings) !== JSON.stringify(newData.layout.settings);

  return mapDirty || rglDirty || settingsDirty;
});

export const getIsEditModalOpen = createSelector([data], (rooms) => rooms.isEditModalOpen);
export const getIsFullScreen = createSelector([data], (rooms) => rooms.isFullScreen);

export const getCurrentThemeName = createSelector(
  [getCurrentLayoutSettings],
  (settings) => settings.theme
);

export const getCurrentThemeSelectOption = createSelector([getCurrentThemeName], (themeName) => {
  const found = options.find((f) => f.value === themeName);
  return found ? found : options[0];
});
