import { lazy } from "react";

const Auth = lazy(async () => await import("../views/auth/view"));
const Admin = lazy(async () => await import("../views/admin/view"));
const Updates = lazy(async () => await import("../views/update/view"));
const Home = lazy(async () => await import("../views/home/view"));
const Pricing = lazy(async () => await import("../views/pricing/view"));
const About = lazy(async () => await import("../views/about/view"));
const Help = lazy(async () => await import("../views/help/view"));

const routes = [
  { label: "Home", component: Home, path: "/" },
  { label: "Pricing", component: Pricing, path: "/pricing" },
  { label: "About", component: About, path: "/about" },
  { label: "Help", component: Help, path: "/help" },
  { label: "Admin", component: Admin, path: "/admin" },
  { label: "Auth", component: Auth, path: "/auth" },
  { label: "Submit", component: Updates, path: "/u/:uid/r/:roomId/up/:updateId" },
];

export default routes;
