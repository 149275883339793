import React, { FC, ReactElement, useMemo, useRef } from "react";
import classnames from "classnames";
import { Button, Modal } from "../..";
import { AnyWidget, Widget } from "../../../types";

import { Settings, Trash2 } from "react-feather";

import styles from "./styles.module.css";
import { Form } from "../../Form";

import { useDispatch, useSelector } from "../../../utils";
import { removeWidgetFromRoom, setIsEditModalOpen } from "../../../views/admin/reducer";
import { defaultWidgetConfigs } from "../config";
import { FieldValue } from "../../Form/Form";
import {
  getCurrentLayout,
  getCurrentRoom,
  getIsEditModalOpen,
  getIsFullScreen,
} from "../../../views/admin/selectors";
import { sanitizeWidget } from "../../Editor/utils";
import { getUser } from "../../../views/auth/selectors";
import { dbUpdateRoom } from "../../../views/admin/api";

interface Props {
  children: ReactElement | ReactElement[];
  canEdit?: boolean;
  widget: AnyWidget;
  initialValues: { [key: string]: any };
}

const WidgetWrapper: FC<Props> = ({ children, canEdit, widget, initialValues }) => {
  const dispatch = useDispatch();

  const isFullScreen = useSelector(getIsFullScreen);
  const isEditing = useSelector(getIsEditModalOpen);

  const user = useSelector(getUser);
  const currentLayout = useSelector(getCurrentLayout);
  const currentRoom = useSelector(getCurrentRoom);

  const ref = useRef<HTMLDivElement>(null);

  const onRemove = () => {
    dispatch(removeWidgetFromRoom({ widgetId: widget.id }));
  };

  const wrapperClassnames = classnames(
    "flex flex-col transition-shadow rounded-md h-full bg-widgetBackground dark:bg-widgetBackgroundDark p-3",
    styles.wrapper
  );

  const headerClassnames = classnames(
    "widget-header flex flex-row justify-between base-02 dark:hover:bg-gray-600 hover:bg-blue-200 items-center transition-opacity hover:shadow-md transition-all rounded-md",
    styles.header
  );

  const contentClassnames = classnames(
    "widget-content h-full overflow-auto transition-opacity transition-transform",
    styles.content,
    { "overflow-hidden": isFullScreen }
  );

  const handleSubmit = (values: { [key: string]: any }) => {
    if (!user?.uid) {
      return;
    }
    const defaultFields = defaultWidgetConfigs[widget.type].fields || {};
    const updatedFields = Object.entries(defaultFields).reduce(
      (acc, [_, val]) => ({
        ...acc,
        ...val.reduce((acc, field, index) => {
          if (field.type === "checkbox" && field.values) {
            const newValue = field.values.reduce((fieldAcc, fieldVal: FieldValue) => {
              const value = Boolean(values[field.accessor]?.[fieldVal.accessor || ""]);

              // console.log(field, fieldVal);
              return {
                ...fieldAcc,
                [field.accessor]: {
                  ...fieldAcc[field.accessor],
                  [fieldVal.accessor || ""]: value,
                },
              };
            }, {} as any);
            return { ...acc, ...newValue };
          } else {
            const newValue =
              typeof values[field.accessor] === undefined
                ? val[index].value
                : values[field.accessor];
            return { ...acc, [field.accessor]: newValue };
          }
        }, {}),
      }),
      {}
    ) as Widget;

    const sanitized = sanitizeWidget(updatedFields) as Widget;
    dispatch(
      dbUpdateRoom({
        uid: user.uid,
        room: {
          ...currentRoom,
          layout: {
            ...currentLayout,
            map: { ...currentRoom.layout.map, [widget.id]: { ...widget, ...sanitized } },
          },
        },
      })
    );

    dispatch(setIsEditModalOpen({ id: widget.id, open: false }));
  };

  const computedInitialValues = useMemo(() => {
    const defaultFields = defaultWidgetConfigs[widget.type].fields || {};

    const ivs = Object.entries(defaultFields).reduce(
      (acc, [_, val]) => ({
        ...acc,
        ...val.reduce(
          (acc, field) => ({ ...acc, [field.accessor]: widget[field.accessor as keyof AnyWidget] }),
          {}
        ),
      }),
      {}
    );

    return { ...ivs, ...initialValues };
  }, [initialValues, widget]);

  return (
    <div ref={ref} className={wrapperClassnames}>
      {!isFullScreen && (
        <div className={headerClassnames}>
          <p>{widget.label}</p>
          <div className="flex flex-row">
            <Button
              onClick={onRemove}
              icon={<Trash2 className="h-5 w-5" />}
              additionalClassnames="icon"
              variant="danger"
            />
            {canEdit && (
              <Button
                onClick={() => {
                  dispatch(setIsEditModalOpen({ id: widget.id, open: !isEditing[widget.id] }));
                }}
                icon={<Settings className="h-5 w-5" />}
                additionalClassnames="icon"
              />
            )}
          </div>
        </div>
      )}
      <div className={contentClassnames}>{children}</div>
      <Modal
        open={!!isEditing[widget.id]}
        size="xl"
        icon={<Settings />}
        handleClose={() => {
          dispatch(setIsEditModalOpen({ id: widget.id, open: false }));
        }}
        title={`Edit`}
      >
        <div className="h-auto z-50">
          <Form
            disabled={!user?.uid}
            initialValues={computedInitialValues}
            fields={defaultWidgetConfigs[widget.type].fields || {}}
            onSubmit={handleSubmit}
            onCancel={() => {
              dispatch(setIsEditModalOpen({ id: widget.id, open: false }));
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default WidgetWrapper;
