import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";

import { appConfig } from "./appConfig";
import { store } from "../store";

import { clearUser, setUser } from "../views/auth/reducer";
import { Room, User } from "../types";
import { setRoom } from "../views/admin/reducer";
// import { setAll } from "../views/admin/reducer";

const firebaseConfig = {
  apiKey: "AIzaSyD6Dl8WncLzVG8qXvXmUsHdviokkyW2It0",
  authDomain: "mydom-fb0fc.firebaseapp.com",
  projectId: "mydom-fb0fc",
  storageBucket: "mydom-fb0fc.appspot.com",
  messagingSenderId: "167529308027",
  appId: "1:167529308027:web:abca889af07c1718cca7de",
  measurementId: "G-K1SXY4VZZD",
};

let roomListener: any;

export const initFirebase = async () => {
  let listeners: { [key: string]: () => void } = {};
  console.log("Initializing Firebase with: ", firebaseConfig);
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    firebase.firestore().enablePersistence();

    if (false && process.env.NODE_ENV === "production") {
      firebase.analytics();
    }
  }

  firebase.auth().onAuthStateChanged(async (user) => {
    console.log("Auth State Changed: ", user);
    if (user && user?.uid) {
      listeners = { ...initFirebaseListeners({ uid: user.uid }) };
    } else {
      listeners.userListener && listeners.userListener();

      store.dispatch(clearUser());
    }
  });

  return firebase;
};

export const setRoomListener = ({ uid, roomId }: { uid: string; roomId: string }) => {
  roomListener = firebase
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("rooms")
    .doc(roomId)
    .onSnapshot((snap) => {
      if (snap.exists) {
        const room = snap.data() as Room;
        store.dispatch(setRoom(room));
      }
    });

  return roomListener;
};

export const initFirebaseListeners = ({ uid }: { uid: string }) => {
  const userRef = firebase.firestore().collection("users").doc(uid);
  const userListener = userRef.onSnapshot(async (snap) => {
    const user = (await snap.data()) as User;
    store.dispatch(setUser(user));
  });

  return { userListener };
};

export const firebaseSignupOptions = [
  // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
  // firebase.auth.GithubAuthProvider.PROVIDER_ID,

  firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  firebase.auth.EmailAuthProvider.PROVIDER_ID,
];

export const firebaseUiConfig = {
  signInOptions: firebaseSignupOptions,
  tosUrl: `${appConfig.baseUrl}/tos`,
  privacyPolicyUrl: function () {
    window.location.assign(`${appConfig.baseUrl}/privacy`);
  },
  signInSuccessUrl: `${appConfig.baseUrl}/admin`,
  callbacks: {
    signInSuccessWithAuthResult: () => {
      return false;
    },
    signInFailure: (error: any) => {
      console.log(error);
    },
    uiShown: () => {},
  },
};
