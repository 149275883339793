import React, { FC, ReactElement, useState } from "react";
import { Transition } from "@headlessui/react";
import { ChevronDown, ChevronUp } from "react-feather";
import classnames from "classnames";

interface Props {
  defaultOpen?: boolean;
  children: ReactElement | ReactElement[];
  title: string;
  icon?: ReactElement;
}

const ExpandableNavSection: FC<Props> = ({ defaultOpen, children, title, icon }) => {
  const [open, setOpen] = useState(!!defaultOpen);

  const toggleOpen = () => setOpen((prev) => !prev);

  const computedIcon = () => {
    if (open) {
      return <ChevronUp />;
    } else {
      if (icon) {
        return icon;
      } else {
        return <ChevronDown />;
      }
    }
  };
  const headerClassnames = classnames(
    "p-2 border-b border-blue-100 dark:border-blue-700 transition-colors hover:bg-blue-100 dark:hover:bg-blue-700 flex flex-row items-center justify-between cursor-pointer",
    {
      "base-03": open,
      "base-01": !open,
    }
  );
  return (
    <div className={open ? "pb-3 " : ""}>
      <div className={headerClassnames} onClick={toggleOpen}>
        <span>{title}</span>
        {computedIcon()}
      </div>

      <Transition
        show={open}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="px-2 my-3">{children}</div>
      </Transition>
    </div>
  );
};

export default ExpandableNavSection;
