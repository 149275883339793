import React, { FC, ReactElement, useMemo } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";

import Button from "../Button";
import { InputWrapper } from "./InputWrapper";

export type FieldType =
  | "emoji"
  | "time"
  | "text"
  | "number"
  | "date"
  | "radio"
  | "textarea"
  | "select"
  | "color"
  | "array"
  | "checkbox";

export type IconType =
  | "eye"
  | "eyeOff"
  | "mic"
  | "micOff"
  | "square"
  | "checkSquare"
  | "maximize"
  | "minimize"
  | "alignLeft"
  | "alignCenter"
  | "alignRight"
  | "rewind"
  | "play"
  | "triangle"
  | "arrowDown"
  | "arrowRight"
  | "arrowUp"
  | "arrowLeft"
  | "circle";

export interface FieldValue {
  label: string;
  description?: string;
  value?: string | boolean;
  icon?: IconType;
  iconActive?: IconType;
  iconInactive?: IconType;
  accessor?: string;
}
export interface FieldConfig<T> {
  disabled?: boolean;
  accessor: string;
  title?: string;
  description?: string;
  type: T;
  icon?: IconType;
  iconActive?: IconType;
  iconInactive?: IconType;
  value?: string | boolean;
  validate?: (value: any) => string | undefined;
  values?: FieldValue[];
  condition?: ConditionProps[];
}

export interface ConditionProps {
  when: string;
  is: string | boolean;
  children: FieldConfig<FieldType>;
}

export interface FormConfig {
  disabled?: boolean;
  loading?: boolean;
  title?: string;
  description?: string;
  fields: { [key: string]: FieldConfig<FieldType>[] };
  onSubmit: (values: { [key: string]: any }) => void;
  onCancel?: () => void;
  initialValues: { [key: string]: any };
  contentPost?: ReactElement | ReactElement[];
}

const Condition = ({ when, is, children }: ConditionProps) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? <InputWrapper {...children} /> : null)}
  </Field>
);

export const Form: FC<FormConfig> = ({
  loading,
  contentPost,
  fields,
  onSubmit,
  onCancel,
  initialValues,
  title,
  description,
  disabled,
}) => {
  const rows = useMemo(() => {
    const cols = (row: FieldConfig<FieldType>[]) => {
      return row.map((col, colIndex) => {
        return (
          <div key={colIndex} className="flex flex-col flex-1 p-3">
            <InputWrapper {...col} />
            {col.condition &&
              col.condition.map((c) => <Condition {...c} key={c.children.accessor} />)}
          </div>
        );
      });
    };
    return Object.entries(fields).map(([key, row], rowIndex) => (
      <div key={`${rowIndex}-${key}`} className="flex flex-row mb-4 flex-1">
        {cols(row)}
      </div>
    ));
  }, [fields]);

  return (
    <>
      <label className="block mb-0  font-medium dark:text-blue-100 text-gray-700">{title}</label>
      <p className="block text-sm font-medium dark:text-blue-100 text-gray-500">{description}</p>
      <FinalForm
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, form, submitting, pristine }) => {
          return (
            <form onSubmit={handleSubmit}>
              {rows}
              <div>{contentPost ? contentPost : null}</div>
              <div className="px-3 mb-5 items-end sm:flex sm:flex-row-reverse">
                <Button
                  additionalClassnames="mb-2 w-full md:w-max"
                  variant="success"
                  disabled={loading || pristine || disabled}
                  label="Save"
                  loading={loading}
                  onClick={handleSubmit}
                />
                <Button
                  additionalClassnames="mb-2 w-full md:w-max"
                  variant="secondary"
                  disabled={loading || submitting || disabled}
                  label="Cancel"
                  type="button"
                  onClick={() => {
                    form.reset();
                    onCancel && onCancel();
                    // setIsEditing(false);
                  }}
                />
              </div>
              {/* {JSON.stringify(values, null, 2)} */}
            </form>
          );
        }}
      </FinalForm>
    </>
  );
};
