import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "../../utils";
import { getCurrentRoom, getHasRooms } from "./selectors";
import { EmptyState, LayoutEditor } from "../../components";

import { getUser } from "../auth/selectors";
import { dbGetAllRooms, dbUpdateRoom } from "./api";

import { Layout } from "../../types";
import { setRoomListener } from "../../config";
import { Redirect } from "react-router-dom";

const Admin: FC = () => {
  const hasRooms = useSelector(getHasRooms);
  const currentRoom = useSelector(getCurrentRoom);

  const user = useSelector(getUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.uid) {
      dispatch(dbGetAllRooms({ uid: user.uid }));
    }
  }, [user?.uid, dispatch]);

  useEffect(() => {
    let listener: any;

    if (currentRoom?.id && user?.uid) {
      console.log("🔥 Subscribe:", currentRoom.id);
      listener = setRoomListener({ uid: user.uid, roomId: currentRoom.id });
    }

    return () => {
      if (listener) {
        console.log("🔥 Unsubscribe:", currentRoom.id);
        listener();
      }
    };
  }, [currentRoom?.id, user?.uid]);

  const handleLayoutUpdate = (layout: Layout) => {
    if (user?.uid && currentRoom?.id) {
      dispatch(dbUpdateRoom({ uid: user.uid, room: { ...currentRoom, layout } }));
    }
  };

  if (!user?.uid) {
    return <Redirect to="/auth" />;
  }

  return (
    <main className="base-01 h-full mx-auto p-0 flex flex-row w-full">
      {!hasRooms && <EmptyState title="Nothing Here" description="Add a room to get started." />}
      {hasRooms && <LayoutEditor onSubmit={handleLayoutUpdate} layout={currentRoom?.layout} />}
    </main>
  );
};

export default Admin;
