import React, { FC } from "react";
import { PlusCircle } from "react-feather";

import { Modal } from "../../components";
import { Room } from "../../types";

import { isRequired } from "../../components/Form/utils";
import { useDispatch, useSelector } from "../../utils";
import { dbAddRoom } from "./api";
import { getUser } from "../auth/selectors";
import { FieldConfig, FieldType, Form } from "../../components/Form/Form";

interface Props {
  open: boolean;
  loading: boolean;
  room?: Room;
  handleClose: () => void;
}

const formConfig: { [key: string]: FieldConfig<FieldType>[] } = {
  title: [
    {
      accessor: "title",
      title: "Room Title",
      description: "",
      type: "text",
      validate: isRequired,
    },
  ],
  // description: [
  //   {
  //     accessor: "description",
  //     title: "Description",
  //     description: "",
  //     type: "text",
  //   },
  // ],
};

export const AddRoomModal: FC<Props> = ({ open, loading, room, handleClose }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const handleSubmit = (values: { [key: string]: string }) => {
    if (user?.uid) {
      const newRoom: Room = {
        name: values.title,
        createdOn: new Date().toISOString(),
        members: [user?.uid],
        admins: [user?.uid],
        active: true,
        layout: {
          map: {},
          rglLayout: [],
          settings: { theme: "myDomDefault" },
        },
        ...(values.description && { description: values.description }),
      };

      dispatch(dbAddRoom({ uid: user.uid, room: newRoom }));
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      title={room ? "Edit Room" : "Add Room"}
      handleClose={handleClose}
      size="xl"
      icon={<PlusCircle />}
    >
      <Form
        loading={loading}
        onCancel={handleClose}
        fields={formConfig}
        onSubmit={handleSubmit}
        initialValues={{}}
      />
    </Modal>
  );
};
