import React, { FC } from "react";
import { useField } from "react-final-form";

import classnames from "classnames";
import { FieldConfig, IconType } from "./Form";
import { iconMap } from "./icons";

export const CheckboxInput: FC<{
  accessor: string;
  label: string;
  description?: string;
  icon?: IconType;
  iconActive?: IconType;
  iconInactive?: IconType;
  value?: boolean;
}> = ({ accessor, label, description, iconActive, iconInactive }) => {
  const field = useField(accessor, { type: "checkbox" });

  const iconWrapperClassnames = classnames(
    "w-8 h-8 flex items-center justify-center p-2 rounded-md bg-info text-white",
    {
      dark: field.input.checked,
    }
  );

  return (
    <div
      className="flex flex-row flex-nowrap items-start mb-3 mr-2 cursor-pointer"
      onClick={() => field.input.onChange(!field.input.checked)}
    >
      <div className="flex mr-2 items-start justify-center">
        {iconActive && iconInactive ? (
          <div className={iconWrapperClassnames}>
            {field.input.checked ? iconMap[iconActive] : iconMap[iconInactive]}
          </div>
        ) : (
          <input {...field.input} />
        )}
      </div>
      <div className="flex flex-col items-start">
        <label className="block mb-0 text-sm font-medium dark:text-blue-100 text-gray-700">
          {label}
        </label>
        {description && (
          <p className="block text-xs dark:text-blue-100 text-gray-500 pr-3 mr-3">{description}</p>
        )}
      </div>
    </div>
  );
};

export const CheckboxInputGroup: FC<FieldConfig<"radio">> = ({
  accessor,

  values,
}) => {
  if (!values) {
    return <p>No Values Provided</p>;
  }

  return (
    <div className="flex flex-col flex-wrap">
      {values?.map((option, index) => (
        <CheckboxInput
          key={index}
          accessor={`${accessor}.${option.accessor}` || ""}
          label={option.label}
          description={option.description}
          iconActive={option.iconActive}
          iconInactive={option.iconInactive}
        />
      ))}
    </div>
  );
};
