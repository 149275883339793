import React, { FC } from "react";
import { PhotoWidget as PhotoWidgetType, Widget } from "../../../types";
import { WidgetWrapper } from "../WidgetWrapper";
import classnames from "classnames";

export interface WidgetComponentProps {
  widget: Widget;
}

const PhotosWidget: FC<PhotoWidgetType> = ({ ...widget }) => {
  const { url, fill, position } = widget;

  const imageClassname = classnames("rounded-lg w-full h-full bg-no-repeat bg-center", {
    "bg-contain": fill === "fit",
    "bg-cover": fill === "fill",
    "bg-top": position === "top",
    "bg-left": position === "left",
    "bg-right": position === "right",
    "bg-bottom": position === "bottom",
    "bg-center": position === "center",
  });
  return (
    <WidgetWrapper initialValues={{}} canEdit={true} widget={widget}>
      <div className={imageClassname} style={{ backgroundImage: `url(${url})` }}></div>
    </WidgetWrapper>
  );
};

export default PhotosWidget;
