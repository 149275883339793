import React, { FC } from "react";
import { Button } from "..";
import { WidgetType } from "../../types";
import { defaultWidgets } from "../Widgets/config";

interface Props {
  onAddWidget: (widgetType: WidgetType) => void;
}

export const WidgetList: FC<Props> = ({ onAddWidget }) => (
  <>
    {defaultWidgets.map((config) => (
      <Button
        additionalClassnames="w-full mx-0 justify-between mb-2  text-sm py-2"
        variant="secondary"
        key={config.type}
        onClick={() => {
          onAddWidget(config.type);
        }}
        icon={config.icon}
        label={config.label}
      />
    ))}
  </>
);
