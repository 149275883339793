import { themes } from "./index";
export interface ITheme {
  [key: string]: string;
}

export interface IThemes {
  [key: string]: ITheme;
}

export interface IMappedTheme {
  [key: string]: string | null;
}

export const mapTheme = (variables: ITheme): IMappedTheme => {
  return {
    "--primary": variables.primary || "",
    "--primaryDark": variables.primaryDark || "",
    "--secondary": variables.secondary || "",
    "--secondaryDark": variables.secondaryDark || "",
    "--tertiary": variables.tertiary || "",
    "--tertiaryDark": variables.tertiaryDark || "",
    "--quaternary": variables.quaternary || "",
    "--quaternaryDark": variables.quaternaryDark || "",
    "--highlight": variables.highlight || "",
    "--highlightDark": variables.highlightDark || "",
    "--background": variables.background || "",
    "--backgroundDark": variables.backgroundDark || "",
    "--widgetBackground": variables.widgetBackground || "",
    "--widgetBackgroundDark": variables.widgetBackgroundDark || "",
  };
};

export const applyTheme = (theme: string): void => {
  const themeObject: IMappedTheme = mapTheme(themes[theme]);
  if (!themeObject) return;

  const root = document.documentElement;

  Object.keys(themeObject).forEach((property) => {
    if (property === "name") {
      return;
    }

    root.style.setProperty(property, themeObject[property]);
  });
};
