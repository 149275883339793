import { useDispatch } from "react-redux";
import { Button, Modal } from "../../components";

import { RefreshCcw, Save, Trash2 } from "react-feather";
import React, { useState } from "react";
import { resetLayout, setLoading, toggleAutoSave } from "../../views/admin/reducer";
import { useSelector } from "../../utils";
import { getCurrentRoom, data, getIsLayoutDirty, getIsLoading } from "../../views/admin/selectors";

import { dbDeleteRoom } from "../../views/admin/api";
import { getUser } from "../../views/auth/selectors";
import { iconMap } from "../Form/icons";
import classnames from "classnames";

export const LayoutSaveForm = ({ handleSubmit }: { handleSubmit: () => void }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const dispatch = useDispatch();

  const currentHouse = useSelector(data);
  const dirty = useSelector(getIsLayoutDirty);
  const loading = useSelector(getIsLoading);
  const user = useSelector(getUser);
  const currentRoom = useSelector(getCurrentRoom);
  const handleReset = () => {
    dispatch(resetLayout());
  };

  const handleDeleteRoom = () => {
    if (user?.uid && currentRoom?.id) {
      dispatch(dbDeleteRoom({ uid: user.uid, room: currentRoom }));
      setDeleteModalOpen(false);
    }
  };

  const iconWrapperClassnames = classnames(
    "w-6 h-6 flex items-center justify-center p-1 rounded-md bg-info text-white",
    {
      dark: currentHouse.autoSave,
    }
  );

  return (
    <>
      <div className="flex flex-col">
        {!currentHouse.autoSave && (
          <div className="flex flex-row">
            <Button
              additionalClassnames="flex flex-1 mr-1"
              onClick={handleReset}
              label="Reset"
              disabled={!dirty}
              icon={<RefreshCcw />}
            />
            <Button
              additionalClassnames="flex flex-1"
              onClick={() => {
                handleSubmit();
                dispatch(setLoading(true));
              }}
              label="Save"
              loading={loading}
              disabled={!dirty}
              variant="success"
              icon={<Save />}
            />
          </div>
        )}
        <div
          className="flex flex-row flex-nowrap items-start mt-3  cursor-pointer"
          onClick={() => dispatch(toggleAutoSave())}
        >
          <div className="flex mr-2 items-start justify-center">
            <div className={iconWrapperClassnames}>
              {currentHouse.autoSave ? iconMap["checkSquare"] : iconMap["square"]}
            </div>
          </div>
          <div className="flex flex-col items-start">
            <label className="block mb-0 text-sm font-medium dark:text-blue-100 text-gray-700">
              Auto Save
            </label>

            <p className="block text-xs dark:text-blue-100 text-gray-500 pr-3">
              If selected, any changes will be saved automatically. Note: this removes the ability
              to reset a layout.
            </p>
          </div>
        </div>
        <Button
          label={`Delete ${currentRoom.name}`}
          additionalClassnames="flex flex-1 mr-1 mt-10"
          onClick={() => {
            setDeleteModalOpen(true);
          }}
          icon={<Trash2 />}
          variant="danger"
        />
      </div>

      <Modal
        handleClose={() => {
          setDeleteModalOpen(false);
        }}
        open={deleteModalOpen}
        title="Are You Sure?"
        icon={<Trash2 />}
        variant="danger"
        size="sm"
      >
        <p>This action can not be undone.</p>
        <div className="py-4 mt-5 sm:flex sm:flex-row-reverse">
          <Button variant="danger" label="Delete" loading={false} onClick={handleDeleteRoom} />
          <Button label="Cancel" loading={false} onClick={() => setDeleteModalOpen(false)} />
        </div>
      </Modal>
    </>
  );
};
