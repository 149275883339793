import { Middleware } from "redux";
import { appConfig } from "../../config/appConfig";
import { clear } from "../reducers/notifications";

export const notificationsMiddleware: Middleware<{}> = (store) => (next) => (action) => {
  const isNotificationAction = action.type.includes("notifications/add");

  if (isNotificationAction) {
    if (action.payload.autoHide) {
      setTimeout(() => {
        store.dispatch(clear(action.payload));
      }, appConfig.notificationTimeout);
    }
  }

  return next(action);
};
