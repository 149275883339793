import React, { FC } from "react";
import classnames from "classnames";

interface Props {
  current: string;
  presets: string[];
  onChange: (color: string) => void;
}
const INPUT_CLASSNAME =
  "dark:text-blue-100 dark:bg-gray-800 focus:ring-blue-600 focus:border-blue-600  block w-full rounded-md  dark:border-gray-600 border-gray-300 text-lg";

const ColorPicker: FC<Props> = ({ presets, current, onChange }) => {
  const swatches = presets.map((hex, index) => {
    const isActive = current === hex;
    const swatchClassname = classnames(
      "cursor-pointer border-2 rounded-md transition-all w-6 h-6 mr-2 mb-2",
      {
        "rounded-full border-blue-400": isActive,
      }
    );

    return (
      <div
        className={swatchClassname}
        style={{ backgroundColor: hex }}
        onClick={() => {
          onChange(hex);
        }}
        key={index}
      />
    );
  });

  return (
    <div>
      <div className="flex flex-row flex-wrap">{swatches}</div>
      <div className="flex flex-row items-center">
        <label className="flex flex-none mr-2 w-max text-sm dark:text-blue-100 text-gray-700">
          Custom Color:{" "}
        </label>
        <input
          className={INPUT_CLASSNAME}
          type="color"
          onChange={(e) => {
            // console.log(e);
            onChange(e.target.value);
          }}
          value={current}
        />
      </div>
    </div>
  );
};

export default ColorPicker;
