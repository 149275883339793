import React, { FC } from "react";
import { useField } from "react-final-form";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import { FieldConfig } from "./Form";

export const EmojiInput: FC<FieldConfig<"emoji">> = ({ type, accessor, validate }) => {
  const field = useField(accessor, { type, validate });

  return (
    <div className="flex flex-row flex-wrap items-start justify-center">
      <div className="flex flex-initial items-center justify-center p-3 mb-3">
        <span className="flex text-7xl bg-blue-200 shadow-md border-2 border-blue-400 rounded-full">
          {field.input.value}
        </span>
      </div>
      <div className="flex flex-1 px-3">
        <Picker
          disableSearchBar={true}
          onEmojiClick={(_, data) => {
            field.input.onChange(data.emoji);
          }}
          disableAutoFocus={true}
          skinTone={SKIN_TONE_MEDIUM_DARK}
          groupNames={{ smileys_people: "PEOPLE" }}
          native
        />
      </div>
    </div>
  );
};
