import React, { FC, ReactElement } from "react";

import { ProfileSection } from "./ProfileSection";
import { RoomSelectSection } from "./RoomSelectSection";
import { Toast } from "..";

export const SlideOutNavSection: FC<{
  icon: ReactElement;
  title: string;
  subtitle?: string;
  children: ReactElement | ReactElement[];
}> = ({ icon, title, subtitle, children }) => {
  return (
    <div className="relative flex flex-col">
      <div className="flex flex-row items-center p-3">
        <div className="base-03 p-2 rounded-md mr-2 text">{icon}</div>
        <div className="flex flex-col">
          <p className="m-0 font-medium">{title}</p>
          {subtitle && <p className="m-0 text-sm ">{subtitle} </p>}
        </div>
      </div>
      <div className="flex flex-col">{children}</div>
    </div>
  );
};

export const Nav = () => {
  return (
    <>
      <nav className="base-02">
        <div className="mx-auto px-4 sm:px-6 lg:px-8 py-2 flex flex-row justify-between">
          <RoomSelectSection />
          <div className="flex flex-1 relative">
            <Toast />
          </div>
          <ProfileSection />
        </div>
      </nav>
    </>
  );
};

export default Nav;
