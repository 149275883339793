import React, { FC } from "react";
import { FieldArray } from "react-final-form-arrays";

import { FieldConfig } from "./Form";
import { TextInput } from "./TextInput";
import { Button } from "..";
import { isRequired } from "./utils";
import { CheckboxInput } from "./CheckboxInput";

export const RssAdditionalKeyInput: FC<FieldConfig<"array">> = ({ accessor }) => {
  return (
    <div className="flex flex-1 flex-col rounded-md  mb-4">
      <FieldArray name={accessor}>
        {({ fields }) => {
          return fields.map((name, index) => {
            return (
              <div
                key={`${name}-${index}`}
                className="flex flex-col mb-4 base-02 rounded-md p-3 shadow-sm "
              >
                <div className="flex flex-row w-full items-end flex-1 mb-2">
                  <div className="flex flex-col flex-1 items-start mr-2">
                    <label className="block mb-0 text-sm font-medium dark:text-blue-100 text-gray-700">
                      Key
                    </label>
                    <div className="mt-2 flex flex-1 w-full mb-3">
                      <TextInput validate={isRequired} type="text" accessor={`${name}.key`} />
                    </div>
                    <CheckboxInput
                      iconActive="checkSquare"
                      iconInactive="square"
                      accessor={`${name}.isHtml`}
                      label="Render as HTML?"
                      description="If checked the content of this field will be rendered as HTML."
                    />
                  </div>

                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => fields.remove(index)}
                    label="Remove"
                  />
                </div>
              </div>
            );
          });
        }}
      </FieldArray>
    </div>
  );
};
