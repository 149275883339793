import { FieldConfig, FieldType } from "../../Form/Form";
import { isRequired } from "../../Form/utils";

export const config: { [key: string]: FieldConfig<FieldType>[] } = {
  "0": [
    {
      accessor: "url",
      title: "Photo URL",
      description: "",
      type: "text",
      validate: isRequired,
    },
  ],
  "1": [
    {
      accessor: "fill",
      title: "Picture Sizing",
      description: "",
      type: "radio",
      values: [
        { label: "Fill", description: "", value: "fill", icon: "maximize" },
        { label: "Fit", description: "", value: "fit", icon: "minimize" },
      ],
    },
  ],
  "2": [
    {
      accessor: "position",
      title: "Picture Position",
      description: "",
      type: "radio",
      values: [
        { label: "Center", description: "", value: "center", icon: "circle" },
        { label: "Top", description: "", value: "top", icon: "arrowUp" },
        { label: "Right", description: "", value: "right", icon: "arrowRight" },
        { label: "Bottom", description: "", value: "bottom", icon: "arrowDown" },
        { label: "Left", description: "", value: "left", icon: "arrowLeft" },
      ],
    },
  ],
};
