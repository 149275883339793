import { createSelector } from "reselect";
import { RootState } from "../../store";

export const data = (state: RootState) => state.notifications;

export const getNotificationsArray = createSelector([data], (notifications) => {
  const array = Object.keys(notifications);
  const length = array.length;

  const lastKey = array[length - 1];
  return notifications[lastKey];
});
