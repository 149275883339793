import { createAsyncThunk } from "@reduxjs/toolkit";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import { User } from "../../types";
import { resetToInitialState } from "../admin/reducer";

export const dbGetUser = createAsyncThunk("user/api/get", async ({ uid }: { uid: string }) => {
  const user = (await (
    await firebase.firestore().collection("users").doc(uid).get()
  ).data()) as User;

  return user;
});

export const signoutUser = createAsyncThunk("user/api/signOut", async (_, { dispatch }) => {
  await firebase.auth().signOut();
  dispatch(resetToInitialState());
});
